import { ReactQuery } from '@codeleap/common'
import { QueryKeys } from '../queryKeys'
import { retrieve } from './views'
import { queryClient } from '../queryClient'

export const useProfile = () => {
  return ReactQuery.useQuery({
    queryKey: QueryKeys.me.key,
    queryFn: retrieve,
    refetchOnWindowFocus: false,
    // refetchOnMount(q) {
    //   return q.state.dataUpdateCount == 0
    // },
    // retry: 3,
    initialData: null,
    refetchInterval: 10000,
    onError() {
      // console.log('ONERROR')
      QueryKeys.me.setData(null)
      queryClient.client.invalidateQueries(QueryKeys.me.key)
    },
  })
}
