import { Attachment } from '@/components'
import { JournalQuestionChoice, Journal } from './journal'
import { Profile, ProfileRole } from './profile'
import { Publication, ScientificTag } from './publication'
import { Replica } from '@/services/api'

export enum InvitationStatusObj {
  accepted = 'accepted',
  sent = 'sent',
  declined = 'declined',
  review_complete = 'Review Completed',
}

export type InvitationStatus = keyof typeof InvitationStatusObj

export type Reviewer = {
  created_datetime: string
  author_id: string
  status?: InvitationStatus
  invites_received?: string
  invites_accepted?: string
  completed_reviews?: string
  review_average_time?: string
}
& Pick<Profile,
  'id' | 'email' | 'full_name' | 'link' | 'affiliations'
  | 'articles' | 'citations' | 'interests' | 'website'
>

export type InvitationReviewer = {
  id: string
  article: Publication['id']
  article_title: string
  created_datetime: string
  status: InvitationStatus
  status_datetime: string
  profile?: Partial<Profile>
  journal?: Pick<Journal, 'title'> & { logo?: string }
  email: string
  full_name: string
  badges: { type: 'reviewer_invite'; id: InvitationReviewer['id'] }[]
}

export type PossibleReviewer = {
  index?: number // Just for frontend use
  id: number
  email: string
  full_name: string
  organisation?: string
  tags?: ScientificTag['id'][]
  suggestion?: boolean
  is_internal?: boolean
}

export type ReviewInvitation = {
  id: number
  name: string
  journalName: string
  status: string
  inviteDate: Date
}

// export type Review = {
//   article: string
//   profile: string
//   status: InvitationStatus
// }

type ReviewStatus = 'draft' | 'submitted'
export type Review = {
  id: number
  form_answers: JournalQuestionChoice
  article: number
  comment: string
  reviewer: Profile
  created_datetime: Date
  media: Attachment[]
  reply: Replica[]
  editor_comment: string
  status: ReviewStatus
  version: number
}

export type Replica = {
  id: number
  created_datetime?: string
  comment?: string
  review: number
}

export type ReplicaMedia = {
  id: number
  created_datetime?: string
  file: string
  file_size: number
  reply: number
  file_category?: number

}
