import { TypeGuards, useCallback, useImperativeHandle, useMemo, useRef } from '@codeleap/common'
import { usePublicationForm } from '@/components'
import { BaseForm } from '../../BaseForm'
import { APIClient } from '@/services'
import { PossibleReviewer } from '@/types'
import { PublicationUtils, useSyncedState } from '@/utils'
import { hasReviewersChanges, isReviewersUnique, isReviewersValid } from './utils'
import { NestedForms } from '..'
import { ReviewerBaseForm } from './ReviewerBaseForm'

const debug = false as const

export const SuggestReviewerForm = () => {
  const publication = usePublicationForm(v => v.publication)
  const possibleReviewers = usePublicationForm(v => v.possibleReviewers)
  const inDrafts = usePublicationForm(v => v.isSaved_in_drafts)
  const ref = usePublicationForm(v => v.modulesRefs.reviewers)
  const { delete: deleteReviewer } = APIClient.Publications.possibleReviewersManager.useDelete()
  const { scientificTags, profile } = APIClient.Session.useSession()

  const initialState: Partial<PossibleReviewer>[] = useMemo(() => {
    if (debug) console.log('initial state', possibleReviewers)

    if (possibleReviewers?.items?.length) return possibleReviewers.items
    return [PublicationUtils.reviewerInitialState]
  }, [possibleReviewers])

  const [forms, setForms] = useSyncedState(initialState, debug)

  const formsRefs = useRef<NestedForms>({})

  const updateForms = useCallback(() => {
    const newForms = Object.values(formsRefs.current)?.map(f => f?.getFormValue?.())?.filter(Boolean)
    setForms(newForms)
    return newForms
  }, [formsRefs])

  const showError = useCallback(() => {
    Object.values(formsRefs.current)?.forEach(f => f?.validateForm?.())
  }, [formsRefs])

  const onClear = useCallback(() => {
    setForms([PublicationUtils.reviewerInitialState])
  }, [])

  const addMoreReviewers = useCallback(() => {
    const values = updateForms()
    setForms([...values, { ...PublicationUtils.reviewerInitialState }])
  }, [updateForms, setForms])

  const onSave = useCallback(async () => {
    const values = updateForms()
    if (hasReviewersChanges(values, initialState)) {
      const trimmedValues = values.map(item => ({
        ...item,
        email: item.email?.trim() || item.email,
        full_name: item.full_name?.trim() || item.full_name,
        organisation: item.organisation?.trim() || item.organisation,
      }))

      await APIClient.Publications.createPossibleReviewers(Number(publication.id), trimmedValues)
    }
  }, [])

  const onValidate = () => {
    const values = updateForms()
    const { isValid, index } = isReviewersValid(values)
    const isUnique = isReviewersUnique(values)
    const hasThreeReviewers = values.length === 3
    const isReviewerEmailSameAsAuthor = values.some(r => r.email?.toLowerCase() === profile?.email)

    let errorMessage: string
    if (!hasThreeReviewers) errorMessage = 'Please suggest at least 3 reviewers to proceed'
    else if (!isUnique) errorMessage = 'Reviewer emails must be unique'
    else if (isReviewerEmailSameAsAuthor) errorMessage = 'Reviewer email must be different from author email'

    const hasIndex = TypeGuards.isNumber(index)
    const anchor = hasIndex ? `suggestedReviewerForm_${index}` : PublicationUtils.fieldAnchors.reviewers
    const scrollProps = hasIndex ? { offset: -16 } : {}

    return {
      isValid: isValid && hasThreeReviewers && isUnique && !isReviewerEmailSameAsAuthor,
      errorMessage,
      anchor,
      scrollProps,
    }
  }

  useImperativeHandle(ref, () => ({
    onValidate,
    onSave,
    onClear,
    showError,
    hasChanges: hasReviewersChanges(forms, initialState),
  }))

  return (
    <BaseForm<Partial<PossibleReviewer>>
      forms={forms}
      setForms={setForms}
      initialState={PublicationUtils.reviewerInitialState}
      onDelete={(item: PossibleReviewer) => item?.id ? deleteReviewer(item) : null}
      showAddMoreButton={forms.length < publication?.reviewers_quantity && inDrafts}
      addMoreButtonProps={{ onPress: addMoreReviewers, id: 'addMore-reviewers' }}
      formComponent={(props) => (
        <ReviewerBaseForm
          ref={(ref) => (formsRefs.current[props.index] = ref)}
          scientificTags={scientificTags}
          disabled={['editor', 'publisher'].includes(profile?.current_role) || !inDrafts}
          {...props}
        />
      )}
      max={publication?.reviewers_quantity}
    />
  )
}
