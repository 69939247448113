import React, { forwardRef } from 'react'
import { ActivityIndicator } from '@/components'
import { EmptyPlaceholder } from '@codeleap/web'
import { useTable } from '@/utils'
import { TableProps } from './types'
import { TableFooter } from './TableFooter'
import { TableRow } from './TableRow'
import { TableHead } from './TableHead'

import './styles.css'

const TableComponent = forwardRef<HTMLDivElement, TableProps>((props, ref) => {
  const {
    columnMap,
    onRowPress = null,
    items,
    showHeader = true,
    showPlaceholder = true,
    placeholderProps,
    PlaceholderComponent,
    header,
    showLoadingOnFooter,
    ...rest
  } = props

  const { mainColumnWidth } = useTable()

  const hasItems = !!items && items?.length >= 1

  return (
    <>
      <div className='table-container' id='table-container' ref={ref}>
        {header}
        {hasItems ? (
          <table className='responsive-table'>
            <TableHead columnMap={columnMap} showHeader={showHeader} />
            <tbody id='body'>
              {items?.map?.((item) => (
                <TableRow
                  key={item?.id + 'table-row'}
                  item={item}
                  columnMap={columnMap}
                  onPress={onRowPress}
                  mainColumnWidth={mainColumnWidth}
                />
              ))}
            </tbody>
          </table>
        ) : (
          showPlaceholder && <PlaceholderComponent {...placeholderProps} debugName='table placeholder' />
        )}
      </div>
      <TableFooter {...rest} items={items} />
      {showLoadingOnFooter ? (
        <ActivityIndicator
          debugName='loading more items on infinity loading'
          variants={['marginHorizontal:auto', 'marginBottom:4']}
        />
      ) : null}
    </>
  )
})

export const Table = forwardRef<HTMLDivElement, TableProps>((props, ref) => {
  const { columnMap: _columnMap, isLoading, PlaceholderComponent = EmptyPlaceholder, placeholderProps } = props

  const columnMap = _columnMap?.filter?.((c) => !!c)

  if (isLoading) return <PlaceholderComponent loading {...placeholderProps} debugName='table placeholder' />
  return <TableComponent {...props} columnMap={columnMap} PlaceholderComponent={PlaceholderComponent} ref={ref}/>
})

export * from './TableRow'
export * from './types'
export * from './defaultComponents'
