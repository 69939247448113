import React, { useCallback, useMemo, useState } from 'react'
import { memoize } from '@codeleap/common'
import { ActionIcon, Text, TextEditor, View, Button, useJournalCrudContext, TextEditorExtensions } from '@/components'
import { JournalQuestion } from '@/types'
import { uuid4 } from '@sentry/utils'
import { modal, useDebouncedEditor, useMemoizedForm } from '@/utils'
import Placeholder from '@tiptap/extension-placeholder'
import { BaseForm } from '@/components/Publication/Forms/BaseForm'
import { AppForms, variantProvider } from '@/app'
import { journalQuestionValidationRules } from '@/app/forms'

type JournalQuestionItemProps = {
  onEdit: (question: JournalQuestion) => void
  onDelete: (question: JournalQuestion) => void
  question: JournalQuestion
  isLast: boolean
}

type JournalQuestionModalProps = {
  question: Partial<JournalQuestion>
}

const MODAL_MAX_WIDTH = 642
const min = journalQuestionValidationRules.question.min
const max = journalQuestionValidationRules.question.max

const JournalQuestionModal = modal<JournalQuestionModalProps>({ id: 'journalQuestionModal', independent: true }).content((props) => {
  const { request, question } = props
  const [currQuestion, setCurrQuestion] = useState(question)

  const [validate, setValidate] = useState(false)

  const form = useMemoizedForm(AppForms.journalQuestionForm, {
    validateOn: 'change', initialState: question,
  })

  const editor = useDebouncedEditor({
    content: currQuestion?.question,
    extensions: [...TextEditorExtensions, Placeholder.configure({ placeholder: 'Text your message here', emptyEditorClass: 'placeholder' })],
    onUpdate: ({ editor }) => form.setFieldValue('question', editor.getHTML()),
    onBlur: () => setValidate(true),
  })

  const error = useMemo(() => {
    let errorMessage: string
    if (editor?.getText().length <= min) errorMessage = `Minimum of ${min} characters`
    if (editor?.getText().length >= max) errorMessage = `Maximum of ${max} characters`

    return validate ? errorMessage : ''
  }, [form.values, validate])

  const handleForm = useCallback((field) => {
    return {
      ...form.register(field),
      id: `journalQuestionForm_input_${question?.id}`,
      _error: !!error,
    }
  }, [form.values, form.fieldErrors, error])

  return <>
    <Text text='Question' variants={['h3', 'marginBottom:2']} />

    <Text text='Question title' variants={['p2', 'color:neutral6', 'marginBottom:1']} />

    <TextEditor
      toolbarComponentProps={{ excludeIds: ['image', 'fileComponent', 'highlight', 'textStyles'] }}
      textEditorProps={{
        editor,
        variants: ['editor:adaptiveHeight', 'multiline'],
        styles: { editor: { maxHeight: 120 }},
        ...handleForm('question'),
        validate: false,
        _error: error,
      }}
      onChangeValue={({ editor }) => setCurrQuestion(prev => ({ ...prev, question: editor.getHTML() }))}
    />
    <View variants={['center', 'fullWidth', 'gap:2', 'marginTop:2']}>
      <Button
        text={'Save'}
        icon='save'
        disabled={!form.values.question || !!error}
        debugName={'Journals question'}
        onPress={() => request?.resolve(form.values)}
        variants={['large']}
      />

      <Button
        variants={[`flat`, 'large']}
        text={`Cancel`}
        debugName={'Journals question'}
        onPress={request?.reject}
      />
    </View>
  </>
}).props({
  variants: ['centered'],
  showClose: false,
  closable: false,
  closeOnEscape: false,
  style: { width: MODAL_MAX_WIDTH },
})

const JournalQuestionItem = (props: JournalQuestionItemProps) => {
  const { question, onDelete, onEdit, isLast } = props

  return (
    <View variants={['column']}>
      <View variants={['fullWidth', 'row', 'justifySpaceBetween', 'alignCenter', 'paddingHorizontal:1', 'paddingVertical:0']} >
        <Text dangerouslySetInnerHTML={{ __html: question.question }} />

        <View variants={['row', 'gap:2']}>
          <ActionIcon
            name={'edit-2'}
            onPress={() => onEdit(question)}
            debugName={'edit journal question icon'}
            variants={['backgroundColor:neutral1', 'primary5', 'iconSize:2', 'normalize']}
          />

          <ActionIcon
            name={'x'}
            onPress={() => onDelete(question)}
            debugName={'delete journal question icon'}
            variants={['backgroundColor:neutral1', 'destructive2', 'iconSize:2', 'normalize']}
          />
        </View>
      </View>
      <View style={!isLast ? styles.separator : null} />
    </View>
  )
}

const EmptyPlaceholder = () => (
  <View variants={['gap:2', 'column', 'fullWidth', 'center', 'paddingVertical:8']}>
    <ActionIcon
      icon='article'
      variants={['backgroundColor:neutral2', 'border-radius:rounded', 'neutral9', 'size:3']}
      debugName={'Journal Questions - List empty placeholder icon'}
      onPress={null}
    />
    <Text text={`You don't have any questions for now.`} variants={['p1', 'color:neutral7']} />
  </View>
)

const JournalQuestionsComponent = () => {
  const { questions, setQuestions, journalId } = useJournalCrudContext()

  const [forms, setForms] = useState(questions)

  const onEdit = (question: JournalQuestion) => {
    JournalQuestionModal.request({ question }).then(onSave).catch(() => null)
  }

  const onDelete = (question: JournalQuestion) => {
    setForms(forms?.filter(q => q?.id !== question?.id))
  }

  const onAdd = () => {
    const initialQuestion = {
      question: '',
      journal: journalId,
      id: ('temp-' + uuid4()) as any,
    }

    JournalQuestionModal.request({ question: initialQuestion }).then(onSave).catch(() => null)
  }

  const onSave = (updatedQuestion) => {
    const newQuestions = forms?.filter(q => q?.id !== updatedQuestion?.id)

    setForms([...newQuestions, updatedQuestion as JournalQuestion])
    setQuestions([...newQuestions, updatedQuestion as JournalQuestion])
  }

  const hasQuestions = forms?.length >= 1

  return (
    <>
      <View variants={['column', 'fullWidth', 'gap:2']}>
        <View variants={['fullWidth', 'column', 'gap:2', 'padding:2', 'border', 'border-radius:small']}>
          <Text text='Questionnaire' variants={['h5', 'color:neutral8']} />

          {hasQuestions ? <>
            <Text text='QUESTION TITLE' variants={['p4', 'marginLeft:1']} />
            <BaseForm
              forms={forms}
              setForms={setForms}
              initialState={questions}
              wrapperProps={{
                variants: ['gap:2', 'column'],
              }}
              formComponent={(props) => {
                return (
                  <JournalQuestionItem {...props} question={props?.value} onDelete={onDelete} onEdit={onEdit} />
                )
              }}
            />
          </> : <EmptyPlaceholder />}

          <View variants={['justifyStart', 'fullWidth', 'marginTop:2']}>
            <Button
              variants={[`flat`]}
              text={`Add Question`}
              debugName={'Journals question'}
              icon={'plus'}
              onPress={onAdd}
            />
          </View>

        </View>
      </View>

      <JournalQuestionModal.Component />
    </>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  separator: {
    backgroundColor: theme.colors.neutral4,
    height: 1,
    width: '100%',
    ...theme.spacing.marginTop(2),
  },
}), true)

export const JournalQuestions = memoize(JournalQuestionsComponent)
