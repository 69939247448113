import { assignTextStyle } from './Text'
import { variantProvider } from '../theme'
import { TagComposition, TagPresets } from '@codeleap/web'

const createTagStyles = variantProvider.createVariantFactory<TagComposition>('wrapper')

const defaultStyles = TagPresets

export const TagStyles = {
  ...defaultStyles,
  default: createTagStyles((theme) => {
    const DEFAULT_HEIGHT = 32
    const backgroundColor = theme.colors.primary1
    const color = theme.colors.neutral8
    const iconSize = theme.values.iconSize[2]

    return {
      wrapper: {
        width: 'fit-content',
        height: DEFAULT_HEIGHT,
        padding: theme.spacing.value(1),
        borderRadius: theme.borderRadius.tiny,
        backgroundColor,
        ...theme.presets.alignCenter,
        ...theme.presets.justifyCenter,
        ...theme.presets.row,
      },
      'wrapper:disabled': {
        cursor: 'not-allowed',
      },
      text: {
        color,
        ...assignTextStyle('p3')(theme).text,
        lineHeight: 16,
        marginVertical: 'auto',
      },
      leftIcon: {
        color,
        height: iconSize,
        width: iconSize,
        marginRight: theme.spacing.value(0.5),
      },
      rightIcon: {
        color,
        height: iconSize,
        width: iconSize,
        marginLeft: theme.spacing.value(0.5),
      },
      rightBadgeWrapper: {
        ...theme.presets.relative,
        marginLeft: theme.spacing.value(0.5),
      },
      leftBadgeWrapper: {
        ...theme.presets.relative,
        marginRight: theme.spacing.value(0.5),
      },
    }
  }),
  'flat': createTagStyles((theme) => ({
    wrapper: {
      borderRadius: theme.borderRadius.tiny * 0.5,
      backgroundColor: theme.colors.primary1,
    },
    leftIcon: {
      color: theme.colors.neutral7,
      height: theme.values.iconSize[1],
      width: theme.values.iconSize[1],
      marginRight: theme.spacing.value(0.5),
    },
    rightIcon: {
      color: theme.colors.neutral7,
      height: theme.values.iconSize[1],
      width: theme.values.iconSize[1],
      marginLeft: theme.spacing.value(0.5),
    },
  })),

  'statusTag': createTagStyles((theme) => ({
    wrapper: {
      borderRadius: theme.borderRadius.nanoish,
      ...theme.spacing.paddingHorizontal(0.5),
      ...theme.spacing.paddingVertical(0.25),
      height: '100%',
    },
    text: {
      ...assignTextStyle('p2')(theme).text,
      textWrap: 'nowrap',
    },
  })),

  // Status colors variants
  'blue': createTagStyles((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.blue4,
    },
    text: {
      color: theme.colors.neutral1,
    },
  })),
  'orange': createTagStyles((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.orange1,
    },
    text: {
      color: theme.colors.neutral10,
    },
  })),
  'yellow': createTagStyles((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.yellow1,
    },
    text: {
      color: theme.colors.neutral10,
    },
  })),
  'darkBlue': createTagStyles((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.blue3,
    },
    text: {
      color: theme.colors.neutral1,
    },
  })),
  'green': createTagStyles((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.green1,
    },
    text: {
      color: theme.colors.neutral1,
    },
  })),
  'red': createTagStyles((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.red1,
    },
    text: {
      color: theme.colors.neutral1,
    },
  })),
  version: createTagStyles((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral1,
      borderRadius: theme.borderRadius.nanoish,
      ...theme.border.neutral8({
        width: 1,
      }),
      ...theme.spacing.padding(0.75),
    },
    text: {
      ...assignTextStyle('p2')(theme).text,
    },
  })),
}
