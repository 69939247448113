import { AppForms, React } from '@/app'
import { Text, View, Button, TextInput, TextEditor, TextEditorRef, TextEditorExtensions } from '@/components'
import { useCallback, useMemo, useRef, useState } from '@codeleap/common'
import { DisclaimerItemProps, DisclaimerModalProps } from '@/types'
import { modal, useDebouncedEditor, useMemoizedForm } from '@/utils'
import Placeholder from '@tiptap/extension-placeholder'
import { journalDisclaimerValidationRules } from '@/app/forms'

const max = journalDisclaimerValidationRules.description.max

export const DisclaimerModal = modal<DisclaimerModalProps>({ id: 'disclaimerModal', independent: true }).content((props) => {
  const { item = null, order = 0, request } = props
  const [currDisclaimer, setCurrDisclaimer] = useState(item)

  const type = !!item ? 'edit' : 'create'
  const textEditorRef = useRef<TextEditorRef>({})

  const form = useMemoizedForm(AppForms.journalDisclaimerForm, {
    validateOn: 'change', initialState: {
      id: currDisclaimer?.id,
      title: currDisclaimer?.title,
      description: currDisclaimer?.body,
    },
  })

  const [validate, setValidate] = useState(false)

  const editor = useDebouncedEditor({
    content: currDisclaimer?.body,
    extensions: [...TextEditorExtensions, Placeholder.configure({ placeholder: 'Text your message here', emptyEditorClass: 'placeholder' })],
    onUpdate: ({ editor }) => form.setFieldValue('description', editor.getHTML()),
    onBlur: () => setValidate(true),
  })

  const error = useMemo(() => {
    let errorMessage: string
    if (editor?.getText().length >= max) errorMessage = `Maximum of ${max} characters`

    return validate ? errorMessage : ''
  }, [form.values, validate])

  const handleForm = useCallback((field) => {
    return {
      ...form.register(field),
      id: `journalDisclaimerForm_input_${item?.id}`,
      _error: error ? true : false,
    }
  }, [form.values, form.fieldErrors, error])

  const onSubmit = () => {

    const data: DisclaimerItemProps = {
      id: item?.id,
      created_datetime: new Date().toISOString(),
      title: form?.values?.title,
      body: form?.values?.description,
      order,
    }

    request?.resolve?.({ type, data })
  }

  const shoudDisableEditSubmit = form?.values?.title === item?.title && form?.values?.description === item?.body || !form?.values?.title

  const submitDisabled = item ? shoudDisableEditSubmit : false

  return (
    <>
      <Text variants={[`h3`, 'marginBottom:3']} text={`Author disclaimer`} />

      <TextInput {...form.register('title')} />

      <View variants={['fullWidth', 'column']}>
        <Text text={'Description'} variants={['p2', 'color:neutral7', 'paddingBottom:1']} />
        <TextEditor
          ref={textEditorRef}
          onChangeValue={({ editor }) => setCurrDisclaimer(prev => ({ ...prev, body: editor.getHTML() }))}
          textEditorProps={{
            editor,
            variants: ['disclaimerModalTextEditor', 'multiline'],
            styles: { editor: { maxHeight: 180 } },
            ...handleForm('description'),
            validate: false,
            _error: error,
          }}
          toolbarComponentProps={{ excludeIds: ['image', 'fileComponent'], variants: ['disclaimerToolbar'] }}
        />
      </View>

      <View variants={['fullWidth', 'marginTop:3', 'marginBottom:4', 'center', 'gap:2']}>
        <Button
          text={`Save`}
          icon={'save'}
          debugName={`On ${type} disclaimer`}
          onPress={onSubmit}
          disabled={!!error || submitDisabled || form.fieldErrors?.title || !form?.values?.title}
          variants={['large']}
        />
        <Button
          text={`Cancel`}
          debugName={`Disclaimer Modal - close modal`}
          onPress={request.reject}
          variants={['flat', 'large']}
        />
      </View>
    </>

  )
}).props({
  showClose: false,
  closable: false,
  closeOnEscape: false,
  variants: ['centered', 'disclaimerModal'],
})
