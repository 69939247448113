import { TypeGuards } from '@codeleap/common'

type StringValidation = {
  notRequired: boolean
  min: number | null
  max: number | null
  isEmail: boolean
}

type SelectValidation = {
  notRequired: boolean
  min: number | null
  max: number | null
  multiple: boolean
}

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

export function isEmailValid(email) {
  return emailRegex.test(email)
}
function isEmpty(value): boolean {
  if (TypeGuards.isArray(value) || TypeGuards.isString(value)) return value.length === 0
  return TypeGuards.isNil(value)
}

export const Validate = {
  String(value, params: Partial<StringValidation> = {} as StringValidation) {
    const _value = value?.trim()
    const { notRequired = false, min = null, max = null, isEmail = false } = params

    let message: string
    if (!notRequired && isEmpty(_value)) message = 'This is a required field'
    else if (min && _value.length < min) message = `Minimum of ${min} characters`
    else if (max && _value.length > max) message = `Maximum of ${max} characters`
    else if (isEmail && !isEmailValid(_value)) message = 'Invalid email address'

    return { valid: TypeGuards.isNil(message), message }
  },

  Select(value = [], params: Partial<SelectValidation> = {} as SelectValidation) {
    const { notRequired = false, min = null, max = null, multiple = false } = params

    let message: string
    if (!notRequired && isEmpty(value)) message = 'This is a required field'
    else if (min && value.length < min) message = `Minimum of ${min} items`
    else if (max && value.length > max) message = `Maximum of ${max} items`

    return { valid: TypeGuards.isNil(message), message }
  },
}
