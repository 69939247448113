import { React } from '@/app'
import { List } from '@/components'
import {
  IconPlaceholder,
  PropsOf,
  QueryManager,
  QueryManagerItem,
  usePagination,
  UseManagerArgs,
  onUpdate,
  useRef,
  useCallback,
} from '@codeleap/common'
import { throttle } from 'lodash'
import { ListProps, PaginationIndicator } from '@codeleap/web'

export type GetFlatlistPropsOptions = {
  noMoreItemsText?: string
  forceLoading?: boolean
  forceHasNextPage?: boolean
  fetchNextPage?: boolean
}

export const useFlatlistProps = <TItem extends QueryManagerItem, T extends QueryManager<TItem, any, any, any>>(
  hookReturn: ReturnType<T['use']>,
  options?: GetFlatlistPropsOptions,
) => {
  const {
    noMoreItemsText = 'No more items to show',
    forceHasNextPage = false,
    forceLoading = false,
    fetchNextPage = true,
  } = options || {}

  const listQuery = hookReturn.list.query

  const hasMore = hookReturn.list.query.isLoading || hookReturn.list.query.hasNextPage

  const showPaginationIndicator = hookReturn.list.query.isFetchingNextPage || !hasMore

  const ListPaginationIndicator = React.useCallback(({ isEmpty = false }) => {
    if (!showPaginationIndicator || isEmpty) return null

    return (
      <PaginationIndicator
        isFetching={hookReturn.list.query.isFetchingNextPage || forceHasNextPage}
        noMoreItemsText={noMoreItemsText}
        hasMore={hasMore}
      />
    )
  }, [showPaginationIndicator, hasMore])

  return {
    data: hookReturn.items as TItem[],
    fetchNextPage: () => {
      if (!hasMore || !hookReturn?.items?.length || !fetchNextPage) return
      hookReturn.getNextPage?.()
    },
    keyExtractor: item => item?.id,
    onRefresh: hookReturn.refresh,
    refreshing: hookReturn.isRefreshing,
    loading: forceLoading || hookReturn.list.query.isLoading,
    ListFooterComponent: ListPaginationIndicator,
    isFetchingNextPage: hookReturn.list.query.isFetchingNextPage,
    ListLoadingIndicatorComponent: () => null,
    hasNextPage: hasMore,
    isLoading: hookReturn.list.query.isLoading,
    placeholder: {
      loading: (listQuery.isFetching || listQuery?.isLoading) && !listQuery?.isRefreshing,
    },
    showPaginationIndicator,
  }
}

type PaginationFlatListProps<TData = any> = Pick<
  ListProps<PropsOf<typeof List>, TData>,
  | 'onRefresh'
  | 'data'
  | 'renderItem'
  | 'placeholder'
  | 'keyExtractor'
  | 'ListFooterComponent'
  | 'ListLoadingIndicatorComponent'
>

export function getFlatListProps<P extends ReturnType<typeof usePagination>>(
  pagination: P,
): PaginationFlatListProps<P['items'][number]> & 'refreshing' {
  const listQuery = pagination.queries.list

  return {
    ...listQuery,
    data: pagination?.items,
    renderItem: () => null,
    keyExtractor: item => pagination.params.keyExtractor(item).toString(),
    refreshing: listQuery.isRefreshing,
    // @ts-ignore
    placeholder: {
      loading: listQuery.isFetching || listQuery?.isLoading || listQuery?.loading || listQuery?.isFetchingNextPage,
      icon: 'placeholderNoItems-select' as IconPlaceholder,
    },
    onRefresh: () => listQuery.refresh(),
    ListLoadingIndicatorComponent: () => {
      const limited = pagination?.items?.length <= pagination?.options?.limit

      if (!pagination?.items?.length || limited) return null

      return (
        <PaginationIndicator
          hasMore={listQuery.hasNextPage}
          noMoreItemsText={`No more ${pagination.itemName}`}
          isFetching={listQuery.isFetchingNextPage}
        />
      )
    },
  }
}

type TablePropsOptions = {
  limit?: number
  showEmpty?: boolean
  resetPages?: boolean
  compareVersionLists?: boolean
  debug?: boolean
  page: number
  setPage?: (page: number) => void
}

export function useTableProps<
  T extends QueryManager<any, any, any, any>,
  TItem = T extends QueryManager<infer I> ? I : never
>(hookReturn: ReturnType<T['use']>, options?: TablePropsOptions) {
  const {
    limit = 10,
    showEmpty = false,
    resetPages = true,
    compareVersionLists = true,
    page = 1,
    setPage,
    debug = false,
  } = options || {}

  const query = hookReturn.list.query

  const items = hookReturn.items

  const numberOfPages = hookReturn.list.totalPages

  const fetchPage = async (page: number) => {
    if (page < 1 || page > numberOfPages) return

    setPage(page)
  }

  const resetQueries = async () => {
    query.remove()
  }

  const reset = async () => {
    resetQueries()
    setPage(1)
  }

  return {
    reset,
    items,
    numberOfItems: hookReturn.list?.query?.data?.count,
    numberOfPages,
    currentPage: page,
    setCurrentPage: setPage,
    query,

    fetchPage,
    fetchNextPage: () => fetchPage(page + 1),
    fetchPreviousPage: () => fetchPage(page - 1),

    resetQueries,
    onRefresh: hookReturn.refresh,
    refreshing: hookReturn.isRefreshing,
    hasNextPage: page < numberOfPages,
    isLoading: query.isLoading || query.isPreviousData,
  }
}

type PaginatedArgs<T extends QueryManagerItem> = Extract<UseManagerArgs<T>, { pagination?: 'paginated' }>

export function useManagerTable<
  T extends QueryManager<any, any, any, any>,
  TItem extends QueryManagerItem = T extends QueryManager<infer I> ? I : never,
  QM extends QueryManager<TItem, any, any, any> = T
>(manager: T, paginationArgs: Omit<PaginatedArgs<TItem>, 'pagination'>) {
  const pagesFetched = useRef([0])
  const [page, setPage] = React.useState(1)

  const isInfiniteScroll = paginationArgs.limit === 500
  const limit = isInfiniteScroll ? 20 : paginationArgs.limit
  const filter = { ...paginationArgs.filter, limit }

  const ininityHookReturn = manager.useList({ filter, limit, queryOptions: { enabled: isInfiniteScroll }})

  const paginatedHookReturn = manager.usePaginatedList({
    filter,
    limit,
    page,
    //@ts-ignore
    queryOptions: { ...paginationArgs.listOptions?.queryOptions, enabled: !isInfiniteScroll },
  }) as ReturnType<QM['usePaginatedList']>

  const hookReturn = isInfiniteScroll ? ininityHookReturn : paginatedHookReturn
  const itemCount = isInfiniteScroll ? ininityHookReturn.query?.data?.pages?.[0]?.count : paginatedHookReturn.query?.data?.count
  const offset = hookReturn.items?.length
  const query = hookReturn.query
  const isLoading = isInfiniteScroll ? ininityHookReturn.query.isLoading : query.isLoading || query.isPreviousData || hookReturn.isRefreshing
  const showLoadingOnFooter = isInfiniteScroll && ininityHookReturn?.query?.isFetchingNextPage
  const numberOfPages = hookReturn.totalPages

  const fetchPage = async (page: number) => {
    if (page < 1 || page > numberOfPages) return
    setPage(page)
  }

  const resetQueries = async () => {
    query.remove()
  }

  const reset = async () => {
    resetQueries()
    setPage(1)
  }

  const fetchOnScroll = throttle(useCallback(async () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 800 && !pagesFetched.current.includes(offset)) {
      pagesFetched.current.push(offset)
      ininityHookReturn.getNextPage()
    }
  }, [offset]), 500)

  onUpdate(() => {
    if (!isInfiniteScroll) return

    window.addEventListener('scroll', fetchOnScroll)
    return () => window.removeEventListener('scroll', fetchOnScroll)
  }, [isInfiniteScroll, fetchOnScroll])

  const tableProps = {
    reset,
    items: hookReturn.items,
    numberOfItems: itemCount,
    numberOfPages,
    currentPage: page,
    setCurrentPage: setPage,
    query,
    fetchPage,
    fetchNextPage: () => fetchPage(page + 1),
    fetchPreviousPage: () => fetchPage(page - 1),
    resetQueries,
    onRefresh: hookReturn.refresh,
    refreshing: hookReturn.isRefreshing,
    hasNextPage: page < numberOfPages,
    isLoading,
    hidePaginationButtons: isInfiniteScroll,
    showLoadingOnFooter,
  }

  return [tableProps, hookReturn] as const
}
