import { modal, useSortModal } from '@/utils'
import React from 'react'
import { PropsOf } from '@emotion/react'
import { Button } from '..'
import { Content, Item } from './components'

export type FilterDataItem = {
  label: string
  value: string | number
  buttonProps?: Partial<PropsOf<typeof Button>>
}

export type SortModalProps = React.PropsWithChildren<{
  options: FilterDataItem[]
  initialState?: FilterDataItem['value'][]
  limit?: number
  hasScroll?: boolean
}>

export const SortModal = modal<SortModalProps, FilterDataItem[]>().content((props) => {
  const {
    // toggle,
    // children,
    request,
    options,
    initialState = [],
    limit,
    ...rest
  } = props

  const sortParams = useSortModal({ options, initialState, request, limit })

  return <>
    <Content renderItem={Item} {...sortParams} {...rest} />
  </>
})

SortModal.props({
  variants: ['centered', 'sectionFiltersModal'],
  title: 'Sorting',
})
