import { getOsAlert, React } from '@/app'
import { Text, View, usePublicationForm, Publication, Button, ManageEditors, ReviewsOverview, ReviewsOverviewProps } from '@/components'
import { AppStatus } from '@/redux'
import { APIClient } from '@/services'
import { Profile, Publication as PublicationType } from '@/types'
import { useManageEditors, useRolePermissions } from '@/utils'
import { PropsOf, TypeGuards } from '@codeleap/common'
import { Badge } from '@codeleap/web'

type PublicationEditorsProps = {
  editors?: Partial<Profile>[]
  editorsInChief?: PublicationType['editors_in_chief']
}

const actionPress = (cb: () => void) => {
  return () => {
    AppStatus.set('loading')
    setTimeout(() => {
      cb()
      AppStatus.set('idle')
    }, 500)
  }
}

const PublicationEditors = (props: PublicationEditorsProps) => {
  const { editors: _editors, editorsInChief } = props

  const editors = []
  for (const editor of _editors) {
    const fullName = `${editor.first_name} ${editor.last_name}`
    if (editor.role_permission === 'editor') {
      editors.push({ id: editor.id, fullName })
    }
  }

  const EditorsColumn = ({ title, editors, type = 'editor' }) => {
    const isChief = type === 'chief'
    if (editors?.length === 0) return null
    return (
      <View variants={['column', 'gap:1', 'flex']}>
        <Text text={title} variants={['p2', 'color:neutral9']} />
        <View variants={['row', 'wrap']}>
          {editors?.length > 0 ? editors.map((editor, index) => {
            const isLast = index === editors?.length - 1
            const fullName = isChief ? `${editor.profile__first_name} ${editor.profile__last_name}` : editor.fullName
            return (
              <View>
                <Text
                  key={isChief ? `${editor.profile__email} ${index}` : editor.id}
                  text={fullName}
                  variants={['p1', 'neutral-8', 'noWrap']}
                />
                {!isLast ? <Text key={editor.id} text={','} variants={['p1', 'neutral-8', 'marginRight:1']} /> : null}
              </View>
            )
          }) : null}
        </View>
      </View>
    )
  }

  return (
    <View
      variants={['row', 'gap:3', 'noWrap']}
      responsiveVariants={{
        small: ['column'],
      }}
    >
      <EditorsColumn
        title={'Editors'}
        editors={editors}
      />
      <EditorsColumn
        type={'chief'}
        title={'Editors-in-Chief'}
        editors={editorsInChief}
      />
    </View>
  )
}

type ActionAreaProps = {
  description?: string
  showBadge?: boolean
  buttonProps: PropsOf<typeof Button>
}

const ActionArea = (props: ActionAreaProps) => {
  const { description, showBadge, buttonProps } = props
  return (
    <View variants={['column', 'gap:0.5']}>
      <View variants={['relative']}>
        <Button
          {...buttonProps}
        />
        {showBadge ? <Badge variants={['position:leftTop', 'small']} badge /> : null}
      </View>
      <Text text={description} variants={['p5', 'neutral-7']} />
    </View>
  )
}

type PublicationBannerProps = {
  isAuthor: boolean
}

export const PublicationBanner = (props: PublicationBannerProps) => {

  const { isAuthor } = props

  const publication = usePublicationForm(v => v.publication)
  const publicationQuery = usePublicationForm(v => v.publicationQuery)
  const refetchPublication = usePublicationForm(v => v.refetchPublication)
  const handleGoToReviewers = usePublicationForm(v => v.handleGoToReviewers)
  const isPublicationArchived = usePublicationForm(v => v.isPublicationArchived)

  const hasEditors = publication?.editors?.length > 0 || publication?.editors_in_chief?.length > 0

  const { editors, manageEditorsModalProps } = useManageEditors({
    journal: publication?.journal?.id,
    publication,
  })

  const { isPublisher, isEditor, isChiefEditor, isManaging } = useRolePermissions()

  const showActionArea = (isPublisher || isManaging || isChiefEditor || isEditor) && !isAuthor
  const finishedPublicationFlow = isPublicationArchived

  const disableManageEditors =
    TypeGuards.isUndefined(editors?.items) ||
    TypeGuards.isUndefined(publication?.editors) ||
    TypeGuards.isUndefined(manageEditorsModalProps?.editors)

  const onSubmitDecision = async ({ type }: ReviewsOverviewProps['data']) => {
    if (isPublicationArchived) {
      return getOsAlert(
        'onOpenRescindDecision',
        { onPress: actionPress(() => ReviewsOverview.open({ data: { historyVersion: false, type }})), status: publication?.status },
      )
    }
    const hasSubmitDecisionBadge = !!publication?.have_badges?.submit_decision.length
    const badgeID = publication?.have_badges?.submit_decision[0]
    actionPress(ReviewsOverview.open({ data: { historyVersion: false, type }}))
    if (hasSubmitDecisionBadge) {
      const response = await APIClient.Badges.readBadge({ type: 'badge_id', id: badgeID })
      if (response) {
        publicationQuery.refetch()
      }
    }
  }

  const decisionMakingButtonProps = {
    text: isPublicationArchived ? 'Rescind Decision' : 'Submit Decision',
    onPress: () => onSubmitDecision({ type: 'decision' }),
    debugName: `${isPublicationArchived ? 'Rescind' : 'Submit'} editor decision`,
  }

  const onNavigateToReviewersAndInvites = async () => {
    const hasReviewersAndInvitesBadge = !!publication?.have_badges?.reviewers_and_invites.length
    const badgeID = publication?.have_badges?.reviewers_and_invites[0]
    handleGoToReviewers()
    if (hasReviewersAndInvitesBadge) {
      const response = await APIClient.Badges.readBadge({ type: 'badge_id', id: badgeID })
      if (response) {
        publicationQuery.refetch()
      }
    }
  }

  return (
    <>
      <View variants={['column', 'gap:2', 'fullWidth']}>
        <View variants={['column', 'backgroundColor:neutral2', 'padding:2', 'border-radius:small', 'gap:3']}>
          <Publication.Toast isEditor={showActionArea && !isEditor} />
          {(showActionArea && !isEditor && hasEditors) ? (
            <PublicationEditors editors={publication?.editors} editorsInChief={publication?.editors_in_chief} />
          ) : null}
        </View>

        {showActionArea ? (
          <View variants={['gap:2', 'fullWidth', 'wrap']}>
            <ActionArea
              description='Manage reviewers invites.'
              showBadge={publication?.have_badges?.reviewers_and_invites?.length > 0}
              buttonProps={{
                debugName: 'Open Reviewers invites',
                text: 'Reviewers and invites',
                icon: 'settings-2',
                variants: ['large', 'manageEditors'],
                onPress: actionPress(onNavigateToReviewersAndInvites),
                disabled: finishedPublicationFlow,
              }}
            />

            {!isEditor ? (
              <ActionArea
                description='Manage the assigned editors.'
                buttonProps={{
                  debugName: 'Open Editors modal',
                  text: 'Manage editors',
                  icon: 'edit',
                  variants: ['large', 'manageEditors'],
                  onPress: actionPress(() => ManageEditors.request().then(refetchPublication)),
                  disabled: disableManageEditors,
                }}
              />
            ) : null}

            {publication?.version > 1 ? (
              <ActionArea
                buttonProps={{
                  icon: 'previous',
                  text: 'View last decision',
                  variants: ['large', 'manageEditors'],
                  onPress: actionPress(() => ReviewsOverview.open({ data: { version: publication?.version - 1, historyVersion: true }})),
                  disabled: !publication?.keywords?.length,
                }}
              />
            ) : null}

            <ActionArea
              buttonProps={{
                icon: 'eyeglass-2',
                text: 'View Reviews',
                debugName: 'View Reviews',
                variants: ['large', 'manageEditors', 'loadingLeft'],
                loadingShowText: true,
                onPress: () => onSubmitDecision({ type: 'view' }),
              }}
            />

            <ActionArea
              showBadge={publication?.have_badges?.submit_decision?.length > 0 && !isPublicationArchived }
              buttonProps={{
                icon: 'eyeglass-2',
                ...decisionMakingButtonProps,
                variants: ['large', 'manageEditors', 'loadingLeft'],
                loadingShowText: true,
              }}
            />

          </View>
        ) : null}
      </View>
      <ManageEditors.Component {...manageEditorsModalProps} />
    </>
  )
}

