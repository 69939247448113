import { ExpandableView, JournalFieldEditor, JournalTab, TemplateVariables, Text, useJournalCrudContext, View } from '@/components'
import { Email, EmailCode } from '@/types'
import { useImperativeHandle, useMemo, useState } from 'react'
import { emailFieldProps } from '../CrudContext/defaultSettings'
import { ActivityIndicator } from '@codeleap/web'
import { emailTemplatesMaxLength, emailTemplatesMinLength } from '@/app/forms'
import { JournalsUtils } from '@/utils'
import { onUpdate, useDebounce } from '@codeleap/common'

type RenderSectionProps = {
  emails: Email[]
  title: string
}

function RenderSection({ emails, title }: RenderSectionProps) {
  return (
    <ExpandableView title={title} initialState contentVariants={['fullWidth', 'column']} id={JournalsUtils.journalsAnchors.emailTemplates}>
      <TemplateVariables variants={['marginBottom:3']} />
      {emails.map((email, index) => {
        const { field, from, to } = emailFieldProps[email.code]

        return <View key={`${email.code}-${index}`} variants={['column', 'gap:1']} id={`${email?.code}-anchor`}>
          <Text variants={['p2']} text={email.title} />
          <View variants={['column']}>
            {from && <Text variants={['p3']} text={`From: ${from}`} />}
            {to && <Text variants={['p3']} text={`To: ${to}`} />}
          </View>
          <JournalFieldEditor
            field={field as any} key={`${email.code}-${index}`}
            renderErrorSpace
            min={emailTemplatesMinLength}
            max={emailTemplatesMaxLength}
          />
        </View>
      })}
    </ExpandableView>

  )
}

function Loading() {
  return (
    <View variants={['justifyCenter', 'alignCenter']}>
      <ActivityIndicator debugName={'EmailTemplateTab loading'} />
    </View>
  )
}

export function EmailTemplateTab({ tab, form }) {
  const { emails } = useJournalCrudContext()
  const ref = useJournalCrudContext(v => v.modulesRefs.emailTemplates)
  const [isValid, setIsValid] = useState<boolean>(false)

  const debouncedFields = {}

  const templateKeys = Object.keys(form?.values || {}).filter(key => key.includes('template'))
  const templateEmailsTab = JournalsUtils?.segmentedControlOptions[2]?.value

  const templateCodes = JournalsUtils.codes?.map((code) => code + '_template')

  templateCodes?.forEach((field) => {
    debouncedFields[field] = useDebounce(form?.values?.[field], 400)
  })

  const debouncedDependencies = Object?.values(debouncedFields)

  onUpdate(() => {
    setIsValid(form?.validateMultiple?.(templateKeys)?.valid)
  }, [
    form?.isValid,
    ...debouncedDependencies,
  ])

  const notificationsEmails = useMemo(() => {
    return emails?.filter((email) => JournalsUtils.codes.includes(email?.code))
  }, [emails])

  const remindersEmails = useMemo(() => {
    const codes: EmailCode[] = ['revise_reminder', 'late_review_reminder']
    return emails?.filter((email) => codes.includes(email?.code))
  }, [emails])

  useImperativeHandle(ref, () => ({
    anchor: (() => {
      const invalidKey = JournalsUtils.getInvalidKey(form?.fieldErrors)

      return invalidKey?.replace(/_template$/, '-anchor') || invalidKey
    })(),
    fieldErrors: form.fieldErrors,
    isValid,
    tab: templateEmailsTab,
  }))

  if (!emails) {
    return (
      <Loading />
    )
  }

  return (
    <JournalTab currentTab={tab} tab='email' style={{ gap: 40 }}>
      <RenderSection emails={notificationsEmails} title='Email notifications' />
      <RenderSection emails={remindersEmails} title='Email reminders' />
    </JournalTab>
  )
}
