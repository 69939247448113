import { createForm, yup } from '@codeleap/common'

export const journalDisclaimerValidationRules = {
  title: {
    min: 3,
    max: 150,
  },
  description: {
    max: 1000,
  },
}

export const journalDisclaimerForm = () => createForm('journalDisclaimerForm', {
  title: {
    type: 'text',
    placeholder: 'Title',
    validate: yup.string().min(journalDisclaimerValidationRules.title.min).max(journalDisclaimerValidationRules.title.max)
      .required(),
  },
  description: {
    type: 'text',
    placeholder: 'Type you message here',
    validate: yup.string().max(journalDisclaimerValidationRules.description.max)
      .required(),
  },
})
