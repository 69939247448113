import { reviewerValidationRules } from '@/app/forms'
import { isEmailValid } from '@/app/forms/validate'
import { PossibleReviewer } from '@/types'
import { deepEqual, TypeGuards } from '@codeleap/common'

export function hasReviewersChanges(forms: Partial<PossibleReviewer>[], initialState: Partial<PossibleReviewer>[]): boolean {
  if (forms.length !== initialState.length) return true

  return !deepEqual(forms, initialState)
}

export function isReviewersValid(forms: Partial<PossibleReviewer>[]): { isValid: boolean; index?: number } {
  for (const reviewer of forms) {
    const isValid =
        reviewer?.full_name?.trim().length >= reviewerValidationRules.full_name.min &&
        isEmailValid(reviewer?.email?.trim()) &&
        reviewer?.organisation?.trim().length >= reviewerValidationRules.organisation.min &&
        TypeGuards.isArray(reviewer?.tags) &&
        reviewer?.tags.length > 0

    if (!isValid) {
      return { isValid: false, index: reviewer?.index }
    }
  }

  return { isValid: true }
}

export function isReviewersUnique(forms: Partial<PossibleReviewer>[]): boolean {
  const emails = forms.map(({ email }) => email?.toLowerCase())
  const uniqueEmails = [...new Set(emails)]

  return uniqueEmails.length === emails.length
}
