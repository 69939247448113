/* eslint-disable max-len */
import { Settings } from '@/app'
import { journalRichTextFieldsMaxLength } from '@/app/forms'
import {
  ExpandableView,
  JournalDisclaimers,
  JournalFieldEditor,
  JournalQuestions,
  JournalRecommendationQuestionForm,
  JournalTab,
  Switch,
  Text,
  useJournalCrudContext,
  View,
} from '@/components'
import { JournalsUtils } from '@/utils'
import { onUpdate, useDebounce, useImperativeHandle, useState } from '@codeleap/common'

const fields = [
  { field: 'about_journal', label: 'About journal', placeholder: 'About journal' },
  { field: 'author_instructions', label: 'Author instructions', placeholder: 'Author instructions' },
  { field: 'reviewer_instructions', label: 'Reviewer instructions', placeholder: 'Reviewer instructions' },
  { field: 'contact_info', label: 'Contact info', placeholder: 'Contact info' },
]

export function SettingsTab({ tab }) {
  const { form } = useJournalCrudContext()
  const ref = useJournalCrudContext(v => v.modulesRefs.settings)

  const settingsTab = JournalsUtils?.segmentedControlOptions[1]?.value

  const [isValid, setIsValid] = useState<boolean>(false)

  const debouncedFields = {}

  const fieldNames = fields.map(f => f.field)

  fieldNames?.forEach((field) => {
    debouncedFields[field] = useDebounce(form?.values?.[field], 400)
  })

  const debouncedDependencies = Object?.values(debouncedFields)

  onUpdate(() => {
    setIsValid(form?.validateMultiple?.(fieldNames)?.valid)
  }, [form?.isValid, ...debouncedDependencies])

  useImperativeHandle(ref, () => ({
    anchor: (() => {
      const invalidKey = JournalsUtils.getInvalidKey(form?.fieldErrors)

      return `${invalidKey}-anchor`
    })(),
    fieldErrors: form.fieldErrors,
    isValid,
    tab: settingsTab,
  }))

  return (
    <JournalTab currentTab={tab} tab='settings' style={{ gap: 40 }}>
      <View variants={['column']}>
        <Text variants={['h4', 'color:neutral10', 'marginBottom:1']} text={`Blinded review process`} />
        <Text variants={[`p1`, 'color:neutral9', 'marginBottom:3']} text={`Enable this option to require the submission of anonymized files for all reviews, ensuring a blinded review process is mandatory.`} />
        <Switch
          {...form.register('requires_blindfolded_review')}
          variants={['left']}
          label={null}
        />
      </View>
      <View variants={['column']}>
        <Text variants={['h4', 'color:neutral10', 'marginBottom:1']} text={`${Settings.AIReviewer.name}  Review`} />
        <Text variants={[`p1`, 'color:neutral9', 'marginBottom:3']} text={`Enable this option to get a review created by our AI for any manuscript version.`} />
        <Switch
          {...form.register('ai_peer_review_enabled')}
          variants={['left']}
          label={null}
        />
      </View>
      <ExpandableView title='Portal content' initialState contentVariants={['fullWidth', 'column']}>
        {fields.map((field) => {
          return (
            <View id={`${field?.field}-anchor`}>
              <JournalFieldEditor {...field} renderErrorSpace excludeIds={['textStyles', 'highlight']} wrapperVariants={['fullWidth']} />
            </View>
          )
        })}
      </ExpandableView>
      <ExpandableView title='Reviewer submission form' initialState contentVariants={['fullWidth', 'column']}>
        <JournalQuestions />
        <JournalRecommendationQuestionForm />
      </ExpandableView>
      <JournalDisclaimers />
    </JournalTab>
  )
}
