import { modal, useSorter, useSortModal } from '@/utils'
import React, { useState } from 'react'
import { Content, Item } from './components'
import { FilterDataItem, SortModalProps } from './Sort'
import { Dashboard, predefinedDatesOptions, View } from '..'
import { isAfter } from 'date-fns'

export type FilterDateModalProps = SortModalProps & React.PropsWithChildren<{}>

const datePickerInitialState = { startDate: null, endDate: null }
export const FilterDateModal = modal<FilterDateModalProps, FilterDataItem[]>().content((props) => {

  const [datePicker, setDatePicker] = useState<Partial<Record<'startDate' | 'endDate', Date>>>(
    { startDate: props?.startDate, endDate: props?.endDate },
  )

  const isDateRangeInvalid = datePicker.startDate && datePicker.endDate ? isAfter(datePicker.startDate, datePicker.endDate) : false

  const hasSelectedDate = datePicker?.startDate || datePicker?.endDate

  const limit = hasSelectedDate ? 0 : 1

  const sortParams = useSortModal({
    options: predefinedDatesOptions,
    initialState: props.initialState,
    request: props.request,
    limit,
    otherArgs: datePicker,
    sectionFilterProps: {
      disableItemsOnLimitReached: hasSelectedDate ? true : false,
    },
  })

  const hasSelectedPredefinedOptions = sortParams?.handle?.selectedItems[0]?.length > 0

  const header = <View variants={['column', 'gap:2', 'fullWidth']}>
    <View variants={['fullWidth', 'row', 'gap:2', 'alignCenter']}>
      <Dashboard.DatePicker
        label={'Start date'}
        value={datePicker?.startDate}
        onValueChange={(e: Date) => setDatePicker({ ...datePicker, startDate: e })}
        debugName='Start date picker'
        disabled={hasSelectedPredefinedOptions}
      />
      <Dashboard.DatePicker
        label={'End date'}
        value={datePicker?.endDate}
        onValueChange={(e: Date) => setDatePicker({ ...datePicker, endDate: e })}
        debugName='End date picker'
        disabled={hasSelectedPredefinedOptions}
      />
    </View>
    <View variants={['fullWidth', 'separator', 'bg:neutral2', 'marginBottom:2', 'marginTop:-3']} />
  </View>

  return <>
    <Content
      renderItem={Item}
      headerContent={
        header
      }
      confirmDisabled={(!hasSelectedDate && !hasSelectedPredefinedOptions) || isDateRangeInvalid}
      {...sortParams}
      onClear={() => {
        props?.request?.resolve?.({ selectedOptions: [], ...datePickerInitialState })
      }}
      {...props}
    />
  </>
})

export const useDateSorter = () => {
  const [datePicker, setDatePicker] = useState<Partial<Record<'startDate' | 'endDate', Date>>>({
    ...datePickerInitialState,
  })

  const sorter = useSorter({
    key: 'DASHBOARD_SORT_DATE',
    options: predefinedDatesOptions,
    modal: FilterDateModal,
    limit: 1,
    handleExtraArgs: (args) => {
      setDatePicker(args)
    },
    enableLocalStorage: false,
    extraArgs: {
      ...datePicker,
    },
  })

  return {
    sorter,
    datePicker,
  }
}

FilterDateModal.props({
  variants: ['sectionFiltersModal', 'centered'],
  title: 'Time filter',
})

