import { modal, useSorter, useSortModal } from '@/utils'
import React, { useState } from 'react'
import { Content, Item } from './components'
import { FilterDataItem, SortModalProps } from './Sort'
import { useArticleTypes } from '@/services/api/session'
import { TypeGuards } from '@codeleap/common'
import { SearchInput } from '@codeleap/web'

export type ArticleTypeModalProps = SortModalProps & React.PropsWithChildren<{}>

export const ArticleTypeModal = modal<ArticleTypeModalProps, FilterDataItem[]>().content((props) => {
  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { data: articleTypes } = useArticleTypes(search || '')

  const _articleTypes = TypeGuards.isNil(articleTypes) ? [] : articleTypes
  const sortParams = useSortModal({ options: _articleTypes, initialState: props.initialState, request: props.request, limit: props.limit })
  const hasSelectedItems = sortParams?.handle?.selectedItems[0]?.length > 0

  const header = <SearchInput
    debugName='Dashboard SearchRegion:SearchInput'
    placeholder='Search a type'
    onSearchChange={setSearch}
    debounce={800}
    clearable
    onTypingChange={(loading) => {
      setIsLoading(loading)
    }}
  />

  return <>
    <Content
      renderItem={Item}
      headerContent={header}
      confirmDisabled={!hasSelectedItems}
      isLoading={isLoading}
      isEmpty={!isLoading && !_articleTypes?.length}
      {...sortParams}
      {...props}
    />
  </>
})

export const useArticleTypeSorter = () => {
  const articleTypes = useArticleTypes()

  const sorter = useSorter({
    key: 'DASHBOARD_SORT_ARTICLE_TYPE',
    options: articleTypes?.data,
    modal: ArticleTypeModal,
    limit: Infinity,
    hasScroll: true,
    enableLocalStorage: false,
  })

  return {
    sorter,
  }
}

ArticleTypeModal.props({
  variants: ['sectionFiltersModal', 'centered'],
  title: 'Select a type',
})

