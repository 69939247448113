import { OSAlert, Theme, React, getOsAlert } from '@/app'
import { AppStatus } from '@/redux'
import { ActivityIndicator, Button, FileInput, Icon, prepareFilesForUpload, Text, Toaster, View } from '../..'
import { APIClient } from '@/services'
import { useMemo, useRef, useState } from '@codeleap/common'
import { modal, useFileCategoryOptions } from '@/utils'
import { Publication } from '@/types'
import { FileItem } from './components/FileItem'
import { fileExtByCategoryValidator, fileSizeValidator } from '@/app/forms'

type BlindfoldedUploadProps = {
  publicationId: Publication['id']
}

export const BlindfoldedUpload = modal<BlindfoldedUploadProps>({ id: 'blindfoldedUpload' }).content((props) => {
  const { publicationId, request } = props

  const fileInputRef = useRef(null)

  const [uploadedFiles, setUploadedFiles] = useState({})
  const [isInvalid, setIsInvalid] = useState({})

  const { data: publication, query: publicationQuery } = APIClient.Publications.publicationsManager.useItem({ id: publicationId })

  const isFirstPublicationVersion = publication?.version === 1
  const currentVersion = publication?.versions.find(v => v?.article_version === publication?.version)

  const articleMedia = APIClient.Publications.mediaManager.use({
    filter: { publication: publicationId },
    listOptions: { queryOptions: {
      enabled: isFirstPublicationVersion,
    }},
  })

  const replyMedia = APIClient.Replica.replicaMediaManager.use({
    filter: { reply: currentVersion?.id },
    listOptions: { queryOptions: {
      enabled: !isFirstPublicationVersion,
    }},
  })

  const file_categories = useFileCategoryOptions({
    show_on_revision: !isFirstPublicationVersion,
    show_on_submission: isFirstPublicationVersion,
  })

  const { initialFiles, isLoadingMedia } = useMemo(() => {
    const response = isFirstPublicationVersion ? articleMedia : replyMedia
    const mainFile = publication?.redacted_file || publication?.file

    const mainFileCategory = file_categories?.find(category => category?.refers_to_main_document)?.id

    const initialFiles = [...(response.items || [])]

    if (isFirstPublicationVersion) {
      initialFiles.unshift({ id: 'main', file: mainFile, file_category: mainFileCategory })
    }

    return {
      initialFiles,
      isLoadingMedia: !response?.list?.query?.isSuccess,
    }
  }, [
    isFirstPublicationVersion,
    articleMedia?.items?.length,
    replyMedia?.items?.length,
    publication?.redacted_file,
    publication?.file,
    file_categories?.length,
  ])
  const { changedFilesLength, hasUploadedAllFiles, submitButtonDisabled } = useMemo(() => {
    const changedFilesLength = Object.keys(uploadedFiles)?.length
    const requiresBlindfold = publication?.requires_blindfolded_upload
    const hasUploadedAllFiles = requiresBlindfold ? changedFilesLength === initialFiles.length : true

    return {
      changedFilesLength,
      hasUploadedAllFiles,
      submitButtonDisabled: !hasUploadedAllFiles || !requiresBlindfold || Object.values(isInvalid).some(x => !!x),
    }
  }, [Object.keys(uploadedFiles)?.length, initialFiles.length, publication?.requires_blindfolded_upload, isInvalid])

  const onMalwareCheck = async () => {
    AppStatus.set('loading')
    const files = { article: publicationId }
    Object.values(uploadedFiles)?.forEach?.((file, index) => {
      const mediaID = initialFiles[index]?.id
      files[mediaID] = file
    })
    try {
      const postedNewFiles = await APIClient.Publications.PostBlindfoldedFiles({ files })
      if (postedNewFiles) {
        AppStatus.set('done')
        request.resolve(true)
      }
    } catch (e) {
      AppStatus.set('idle')
      getOsAlert('blindedReviewFilesError')
      request.reject(null)
    }
  }

  const onSubmitNewFiles = async () => {
    const result = await prepareFilesForUpload({ files: Object.values(uploadedFiles), showPicker: false, showConfirmation: false })

    if (!result) return
    onMalwareCheck()
    publicationQuery.refetch()
  }

  const onUploadFile = async (index, fileCategory) => {
    const file = await fileInputRef.current.openFilePicker()
    if (file) {
      const newFiles = { ...uploadedFiles, [index]: file[0]?.file }
      const checkFileSize = fileSizeValidator(file[0]?.file)
      const checkExt = fileExtByCategoryValidator(file[0]?.file, fileCategory)
      setUploadedFiles(newFiles)
      setIsInvalid({ ...isInvalid, [index]: checkFileSize?.message || checkExt?.message })
    }
  }

  const onRemoveItem = (index) => {
    const newItems = { ...uploadedFiles }
    delete newItems[index]
    setUploadedFiles(newItems)
  }

  if (!publicationQuery?.isSuccess || isLoadingMedia) {
    return <View variants={['fullWidth', 'center']} style={styles.loadingWrapper}>
      <ActivityIndicator debugName={'Activity indicator - loading blindfoldedUpload'} />
    </View>
  }

  return (
    <>
      <Toaster.BlindfoldedToaster />

      <View variants={['center', 'marginTop:4', 'marginBottom:2', 'fullWidth', 'column']}>
        <View variants={['alignCenter', 'marginBottom:1.25', 'gap:1']}>
          {hasUploadedAllFiles ? (
            <Icon
              name={'check-circle-2'}
              variants={['positive2', 'small']}
              debugName={'Blindfolded Journal Flow - File upload completion icon indicator'}
            />
          ) : null}
          <Text
            variants={[`h3`, 'color:neutral8']}
            text={`${hasUploadedAllFiles ? initialFiles?.length : changedFilesLength}/${initialFiles?.length} Edited files uploaded`}
          />
        </View>
        <Text
          variants={[`p3`, 'color:neutral7']}
          text={`Download the original files and upload them with the author's names edited out.`}
        />
      </View>

      <View style={styles.listWrapper}>
        {initialFiles?.map((file, index) => {
          const fileCategory = file_categories?.find?.(category => category?.id === file?.file_category)
          return <FileItem
            key={index}
            initialFile={file}
            updatedFile={uploadedFiles?.[index]}
            index={index}
            disableUpload={!publication?.requires_blindfolded_upload}
            fileCategory={fileCategory}
            onUpload={() => onUploadFile(index, fileCategory)}
            onRemove={() => onRemoveItem(index)}
            showError
          />
        })}
      </View>

      <View variants={['fullWidth', 'center', 'marginTop:4']}>
        <Button
          variants={[`blindfoldedSubmitButton`]}
          text={`Continue to security verification`}
          debugName={`Blindfolded journal flow - on submit`}
          disabled={submitButtonDisabled}
          onPress={onSubmitNewFiles}
        />
      </View>

      <FileInput ref={fileInputRef} id={'BlindfoldedFileInput'} />
    </>
  )
}).props({
  showClose: true,
  dismissOnBackdrop: true,
  variants: ['centered', 'blindfoldedReview'],
  title: 'Upload required files',
})

const styles = {
  listWrapper: {
    maxHeight: Theme.values.itemHeight.default * 6.5,
    ...Theme.spacing.paddingTop(1),
    ...Theme.presets.column,
    ...Theme.presets.fullWidth,
    overflowY: 'auto',
  },
  link: {
    cursor: 'pointer',
  },
  loadingWrapper: {
    minHeight: 200,
  },
  fileItemName: {
    display: 'block', // Fix for not working -webkit-box
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'unset',
    minHeight: 23,
    wordBreak: 'break-all',

    [Theme.media.down('small')]: {
      display: 'block', // Fix for not working -webkit-box
      display: '-webkit-box',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
      whiteSpace: 'unset',
    },
  },
}
