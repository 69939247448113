import { React, Theme, variantProvider } from '@/app'
import { Button, View, List, Toaster, Text, Icon } from '@/components'
import { PropsOf, useState } from '@codeleap/common'
import { Profile, Publication } from '@/types'
import { AppStatus } from '@/redux'
import { APIClient } from '@/services'
import { SearchInput, SearchInputProps } from '@codeleap/web'
import { modal } from '@/utils'

export type ManageEditorsProps = {
  editors: Profile[]
  listProps: Partial<PropsOf<typeof List>>
  publication: Publication['id']
  publicationEditors: Publication['editors']
  searchInputProps?: Omit<SearchInputProps, 'debugName'>
  onSave?: () => void
  profileId: Profile['id']
}

const EditorsWrapper = ({ selectedEditors, onClose }) => {

  if (selectedEditors?.length === 0) return null

  return (
    <View variants={['row', 'gap:1', 'wrap', 'marginBottom:3']}>
      {selectedEditors?.map((editor) => {

        return (
          <Toaster.Component
            key={editor.id}
            title={`${editor.first_name} ${editor.last_name}`}
            isVisible
            showClose
            variants={['manageEditor']}
            closeButtonProps={{
              onPress: () => onClose(editor.id),
              styles: {
                touchableWrapper: styles.closeWrapper,
                icon: styles.closeIcon,
              },
            }}
          />
        )
      })}
    </View>
  )
}

export const ManageEditors = modal<ManageEditorsProps>({ independent: true }).content((props) => {

  const { toggle, editors, listProps, publication, publicationEditors, searchInputProps, request } = props

  const { onSearchChange, ...otherSearchProps } = searchInputProps

  const [selectedEditors, setSelectedEditors] = useState<Partial<Profile>[]>(publicationEditors)

  const unselectEditor = (editorId) => {
    let result = []
    setSelectedEditors((state) => {
      result = state.filter((v) => v.id !== editorId)

      return result
    })
  }

  const checkIsSelected = (editorId) => {
    return selectedEditors?.some((item) => item.id === editorId)
  }

  const editorPress = (editor: Profile, isSelected: boolean) => {
    setSelectedEditors((state) => {
      let result = []
      if (state === undefined) return result
      if (isSelected) {
        result = state.filter((v) => v.id !== editor.id)
      } else {
        result = [...state, editor].filter(Boolean)
      }

      return result
    })
  }

  const handleCancel = () => {
    toggle()
    onSearchChange('')
  }

  const handleSave = async () => {
    toggle()
    AppStatus.set('loading')
    const formatedData = selectedEditors?.map((item) => item.id)

    try {
      await APIClient.Publications.assignEditors(publication, formatedData)
      AppStatus.set('done')
    } catch (error) {
      AppStatus.set('idle')
      logger.error('Error assigning an Editor:', error)
    }
    request?.resolve?.(null)
  }

  const renderItem = ({ item }) => {
    const editor = item as Profile
    const isSelected = checkIsSelected(editor?.id)

    return (
      <Button
        debugName={editor?.id}
        onPress={() => editorPress(editor, isSelected)}
        variants={['selectEditors', 'fullWidth']}
        styles={{
          wrapper: {
            // ...styles.itemWrapper,
            ...(isSelected && styles['itemWrapper:selected']),
          },
        }}
      >
        <View variants={['row', 'alignCenter', 'gap:2', 'justifySpaceBetween', 'fullWidth']}>
          <View variants={['column', 'alignStart']}>
            <Text text={`${editor?.first_name} ${editor?.last_name}`} variants={['capitalize', 'p1', 'neutral-8', 'regular', 'textLeft']} style={{ ...(isSelected && styles['itemText:selected']) }}/>
            <Text text={editor?.organisation} variants={['capitalize', 'p3', 'color:neutral5']} style={{ ...(isSelected && styles['itemText:selected']) }}/>
          </View>
          {isSelected ? <Icon debugName='ManageEditors:icon' color={Theme.colors.light.neutral1} name='check' size={Theme.values.iconSize[1]}/> : null}
        </View>
      </Button>
    )
  }

  return (
    <>
      <View css={styles.wrapper}>
        <EditorsWrapper selectedEditors={selectedEditors} onClose={unselectEditor} />
        <SearchInput
          debugName='SelectEditors:Search'
          placeholder='Search'
          onValueChange={onSearchChange}
          onSearchChange={onSearchChange}
          leftIcon={null}
          clearable={false}
          styles={{
            innerWrapper: styles.input,
          }}
          {...otherSearchProps}
        />
        <List
          debugName='SelectEditor:List'
          renderItem={renderItem}
          style={styles.list}
          {...listProps}
          data={editors as unknown as string[]}
        />
      </View>
      <View variants={['fullWidth', 'center', 'gap:2']}>
        <Button
          debugName='ManageEditors:Save'
          text='Save'
          variants={['large', 'manageEditors']}
          onPress={handleSave}
        />
        <Button
          debugName='ManageEditors:Cancel'
          text='Cancel'
          variants={['large', 'manageEditors', 'neutral2']}
          onPress={handleCancel}
          debounce={1000}
        />
      </View>
    </>

  )
})

const LIST_HEIGHT = 432

const styles = variantProvider.createComponentStyle((theme) => ({
  // itemWrapper: {
  //   maxWidth,
  // },
  'itemWrapper:selected': {
    backgroundColor: theme.colors.primary5,

    '&:hover': {
      backgroundColor: theme.colors.primary5,
    },
  },
  itemText: {
    fontWeight: '400',
    ...theme.spacing.marginRight('auto'),
  },
  'itemText:selected': {
    color: theme.colors.neutral1,
  },
  closeWrapper: {
    width: theme.values.iconSize[2],
    height: theme.values.iconSize[2],
  },
  closeIcon: {
    color: theme.colors.neutral7,
    width: theme.values.iconSize[2],
    height: theme.values.iconSize[2],
  },
  list: {
    minHeight: 0,
    ...theme.presets.fullHeight,
    ...theme.presets.fullWidth,
    overflowY: 'auto',
  },
  input: {
    borderRadius: theme.borderRadius.tiny,
  },
  wrapper: {
    height: LIST_HEIGHT,
    ...theme.presets.column,
  },
}), true)

ManageEditors.props({
  title: 'Select editors',
  variants: ['manageEditors', 'centered'],
  showClose: false,
})
