import { variantProvider } from '../theme'
import { includePresets } from '@codeleap/common'
import { assignTextStyle } from './Text'

export type HeaderComposition =
  'wrapper' |
  'wrapper:open' |
  'wrapperFloating' |
  'wrapperFloating:open' |
  'drawerWrapper' |
  'drawerBox' |
  'drawerOverlay' |
  'navButton' |
  'navItem' |
  'navItem:selected' |
  'logo' |
  'logoWrapper'

const createHeaderStyle = variantProvider.createVariantFactory<HeaderComposition>()

const presets = includePresets((s) => createHeaderStyle(() => ({ wrapper: s })))

const BREAKPOINT = 'mid'
const navButtonHeight = 51
const navButtonWidth = 127
const logoSize = 32

export const HeaderStyles = {
  ...presets,
  default: createHeaderStyle((theme) => ({
    'wrapper': {
      ...theme.presets.row,
      ...theme.presets.alignCenter,
      backgroundColor: theme.colors.background,
      paddingTop: theme.spacing.value(2.5),
      paddingBottom: theme.spacing.value(2.5),
      height: theme.values.headerHeight,
      alignItems: 'center',
    },
    'wrapper:open': {
      boxShadow: null,
      backgroundColor: theme.colors.background,

      [theme.media.down(BREAKPOINT)]: {
        backgroundColor: theme.colors.background,
        position: 'fixed',
        transform: 'translateY(50%)',
      },
    },
    'wrapper:top': {
      ...theme.effects.smooth,
      [theme.media.down(BREAKPOINT)]: {
        backgroundColor: theme.colors.background,
      },
    },
    'wrapperFloating': {
      ...theme.presets.row,
      position: 'static',
      zIndex: 2,
      top: 0,
      left: 0,
      right: 0,
      backgroundColor: theme.colors.background,
      transition: 'all 0.2s ease-in-out',

      [theme.media.down(BREAKPOINT)]: {
        background: theme.colors.transparent,
      },
    },
    'wrapperFloating:open': {
      backgroundColor: theme.colors.background,

      [theme.media.down(BREAKPOINT)]: {
        backgroundColor: theme.colors.background,
      },
    },
    'wrapperFloating:top': {
      [theme.media.down(BREAKPOINT)]: {
        backgroundColor: theme.colors.background,
      },
    },
    'drawerWrapper': {
      zIndex: -1,
      maxWidth: '33%',
      [theme.media.down('xlarge')]: {
        maxWidth: '30%',
      },
      [theme.media.down('large')]: {
        maxWidth: '27%',
      },
      [theme.media.down(BREAKPOINT)]: {
        maxWidth: '100%',
      },
    },
    'drawerBox': {
      ...theme.presets.column,
      ...theme.presets.alignStart,
      alignItems: 'end',
      justifyContent: 'start',
      gap: theme.spacing.value(2),
      maxWidth: '33%',
      backgroundColor: theme.colors.neutral1,

      [theme.media.down('xlarge')]: {
        maxWidth: '30%',
      },
      [theme.media.down('large')]: {
        maxWidth: '27%',
      },
      [theme.media.down(BREAKPOINT)]: {
        maxWidth: '100%',
        width: '100vw',
        height: '100vh',
      },
    },
    'drawerOverlay': {
      opacity: 0,
      display: 'none',
    },
    'navItem': {
      ...assignTextStyle('h4')(theme).text,
      ...theme.presets.textCenter,
      color: theme.colors.neutral9,
      fontWeight: '700',
      textDecoration: 'none',
      letterSpacing: 0,
      ...theme.spacing.paddingVertical(2),
      ...theme.spacing.paddingHorizontal(2.28125),

      '&:hover': {
        color: theme.colors.primary3,
      },

      [theme.media.down(BREAKPOINT)]: {
        ...assignTextStyle('h4')(theme).text,
        backgroundColor: theme.colors.transparent,
        borderRadius: theme.borderRadius.tiny,
        ...theme.spacing.paddingHorizontal(0.775),
      },
    },
    'navItem:selected': {
      color: theme.colors.primary3,

      [theme.media.down(BREAKPOINT)]: {
        color: theme.colors.primary3,
        fontWeight: '700',
      },
    },
    navButton: {
      whiteSpace: 'nowrap',
      ...theme.spacing.paddingHorizontal(2.5),
      ...theme.spacing.paddingVertical(1.5),
      ...theme.spacing.marginLeft(2),
      backgroundColor: theme.colors.neutral10,
      minHeight: navButtonHeight,
      minWidth: navButtonWidth,

      [theme.media.down(BREAKPOINT)]: {
        ...theme.spacing.marginLeft(0),
      },
    },
    logo: {
      width: logoSize * 5,
      [theme.media.down(BREAKPOINT)]: {
        width: logoSize,
      },
    },
    logoWrapper: {
      marginRight: 'auto',

      [theme.media.down(BREAKPOINT)]: {
        marginRight: theme.spacing.value(0),
        display: 'flex',
        ...theme.presets.justifyCenter,
      },
    },
  })),
}
