import { variantProvider } from '@/app'
import {
  Text,
  View,
} from '@/components'

export const EditorColumn = ({ editors }) => {
  const _editors = editors?.filter((editor) => editor?.role_permission !== 'owner')

  const editor = _editors?.[0]
  const editorsQty = _editors?.length as number

  if (editorsQty === 0) return null

  return (
    <View css={styles.wrapper}>
      <Text
        variants={['ellipsis', 'wrap']}
        text={`${editor?.first_name} ${editor?.last_name}`}
        css={[styles.editorNameColumn]}
      />
      <Text
        text={`${!!(editorsQty - 1) ? '+' + (editorsQty - 1) : ''}`}
      />
    </View>
  )
}

const LINE_HEIGHT = 18
const MAX_LINES = 3

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    width: 200,
    ...theme.presets.flex,
    ...theme.presets.center,
    ...theme.presets.fullHeight,
    ...theme.spacing.gap(0.5),
    ...theme.presets.column,
  },
  editorNameColumn: {
    whiteSpace: 'normal',

    display: 'block', // Fix for not working -webkit-box
    display: '-webkit-box',
    lineHeight: LINE_HEIGHT + 'px',
    maxHeight: LINE_HEIGHT * MAX_LINES,
    ...theme.spacing.margin(0),

    WebkitLineClamp: MAX_LINES,
    WebkitBoxOrient: 'vertical',

    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all',
  },
}), true)
