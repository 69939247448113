import React from 'react'
import { Text, TextEditor, TextEditorExtensions, View, useJournalCrudContext } from '@/components'
import { JournalCrudProviderData } from '../CrudContext/types'
import Placeholder from '@tiptap/extension-placeholder'
import { onUpdate, useRef, useState } from '@codeleap/common'
import { useDebouncedEditor } from '@/utils'
import { journalRichTextFieldsMaxLength } from '@/app/forms'

type JournalFieldEditorProps = {
  field: keyof JournalCrudProviderData['form']['values']
  placeholder?: string
  label?: string
  renderErrorSpace?: boolean
  excludeIds?: string[]

  showValidationMsg?: boolean
  min?: number
  max?: number

  wrapperVariants: string[]
}

export const JournalFieldEditor = (props: JournalFieldEditorProps) => {
  const { field, placeholder, renderErrorSpace, label, excludeIds = [], showValidationMsg = true, max = journalRichTextFieldsMaxLength, min = 0, wrapperVariants = [] } = props
  const { form, isCreate } = useJournalCrudContext()
  const loaded = useRef(false)

  const [validate, setValidate] = useState(false)

  const editor = useDebouncedEditor({
    content: form?.values?.[field],
    extensions: [
      ...TextEditorExtensions,
      Placeholder.configure({ placeholder: placeholder, emptyEditorClass: 'placeholder' }),
    ],
    editorProps: {
      transformPastedHTML: (html) => html.replace(/<img.*?>/g, ''),
    },
    // Ensure empty content sets the field to '' since `getHTML` includes empty tags.
    onUpdate: ({ editor }) => form.setFieldValue(field, editor.isEmpty ? '' : editor.getHTML()),
    onBlur: () => setValidate(showValidationMsg ? true : false),
  })

  onUpdate(() => {
    if (form?.values?.[field] && !loaded.current && isCreate) {
      loaded.current = true
    }
  }, [form?.values?.[field]])

  const isMaxValid = form.values?.[field]?.length <= max
  const isMinValid = form.values?.[field]?.length >= min
  const journalFieldFormValue = showValidationMsg ? form.fieldErrors[field] : ''

  const hasError = validate ? !isMaxValid || !isMinValid : false
  const renderSpace = renderErrorSpace && !hasError

  return (
    <View variants={['column', renderSpace && 'marginBottom:3', ...wrapperVariants]}>
      {label && <Text variants={['p2', 'color:caption', 'marginBottom:1']} text={label} />}
      <TextEditor
        toolbarComponentProps={{ editor, excludeIds: ['image', 'fileComponent', ...excludeIds] }}
        textEditorProps={{
          editor,
          variants: ['editor:adaptiveHeight', 'multiline', 'noError'],
          _error: validate && hasError || journalFieldFormValue.length >= 1,
          validate: validate && (isMaxValid || isMinValid) ? false : form.register(field)?.validate,
          ...form.register(field),
        }}
      />
      {journalFieldFormValue.length >= 1 && min === 1 ? (
        <Text variants={['p3', 'color:destructive2', 'marginTop:1']} text={journalFieldFormValue} />
      ) : (
        <>
          {validate && !isMinValid && <Text variants={['p3', 'color:destructive2', 'marginTop:1']} text={`Minimum of ${min} characters`} />}
          {validate && !isMaxValid && <Text variants={['p3', 'color:destructive2', 'marginTop:1']} text={`Maximum of ${max} characters`} />}
        </>
      )}
    </View>
  )
}
