import { AppStatus } from '@/redux'
import { ActionIcon, Button, Dropzone, Icon, ReviewComment, Select, Text, TextEditor, TextEditorExtensions, View, selectPublicationUploadFiles } from '..'
import { useMemo, useState } from 'react'
import { TypeGuards } from '@codeleap/common'
import { APIClient } from '@/services'
import { variantProvider, React, getOsAlert } from '@/app'
import { getSelectValues, modal, PublicationUtils, useFileCategoryOptions } from '@/utils'
import { DropzoneRef } from '@codeleap/web'
import { Publication } from '@/types'
import { useEditor } from '@tiptap/react'
import { validateFileExtension } from '@/app/forms'

type SubmitNewPublicationVersionProps = {
  publication: Publication
  data?: { files: File[]; comment?: string }
}

const requiredCategories = ['refers_to_main_document', 'response_to_reviewer_or_editor', 'clean_version_of_revised_article_with_changes_accepted']

export const SubmitNewPublicationVersion = modal<SubmitNewPublicationVersionProps>({ id: 'submitNewPublicationVersion', independent: true }).content((props) => {
  const { toggle, publication } = props

  const ref = React.useRef<DropzoneRef>()

  const [_attachments, setAttachments] = useState([])
  const [disableSubmit, setDisableSubmit] = useState(false)
  const editor = useEditor({ extensions: TextEditorExtensions })

  const attachments = _attachments ?? []

  const publications = APIClient.Publications.publicationsManager.useUpdate()

  const historyList = APIClient.Publications.publicationHistoryManager.useList({
    filter: {
      article: publication?.id,
    },
    queryOptions: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 0,
      enabled: false,
    },
  })

  const file_categories = useFileCategoryOptions({ version: publication?.version, status: publication?.status })

  const mainDocumentCategory = useMemo(() => {
    return file_categories?.find(e => !!e.refers_to_main_document)
  }, [file_categories?.length])

  const fileCategoryValues = useMemo(() => {

    return attachments.map((attachment, index) => {
      const category = attachment?.category || attachment?.file_category
      return getSelectValues(category, file_categories)

    })

  }, [attachments, file_categories?.length, mainDocumentCategory?.id])

  const onSaveNewComment = (comment: string) => {
    editor?.commands?.setContent(comment)
  }

  const onOpenReviewCommentModal = () => {
    ReviewComment.request({ comment: editor.getHTML() })
      .then(onSaveNewComment)
  }

  const onRemoveAttachment = (attachment) => {
    setAttachments((state) => {
      const items = { ...state }
      const index = state?.findIndex(item => JSON.stringify(item) === JSON.stringify(attachment))
      delete items[index]
      return Object.values(items)
    })
  }

  const onSetCategory = ({ category, index }) => {
    setAttachments((state) => {
      if (state) {
        const newState = [...state]
        newState[index] = { ...newState[index], category: category }

        return newState
      }
      return state
    })
  }

  const onSubmitVersion = async () => {
    setDisableSubmit(true)
    AppStatus.set('loading')
    try {
      const response = await APIClient.Replica.PostReplica({ comment: editor.getHTML(), article: publication.id })
      if (response) {
        publications.update({
          id: publication?.id,
          status: 'revision_completed',
        })
        await APIClient.Replica.PostReplicaMedia({ files: attachments, reply: response?.id })
        await APIClient.Replica.updateReplica({ id: response?.id, ready: true })
        setAttachments([])
        await historyList.query.refetch()
        toggle()
        AppStatus.set('done')
      }
    } catch (e) {
      setTimeout(() => setDisableSubmit(false))
      AppStatus.set('idle')
      getOsAlert('newVersionPublicationError')
    }
  }

  const Comment = () => {

    if (!editor?.getText?.()) {
      return (
        <Button
          variants={['small', 'backgroundColor:neutral2', 'text:p2', 'text:primary5', 'icon:primary5', 'marginBottom:3', 'marginTop:1', 'border-radius:nano', 'flat:hover']}
          text={`Add Comment`}
          icon={'plus'}
          debugName={`Submit new Manuscript version Modal - Add comment`}
          onPress={onOpenReviewCommentModal}
        />
      )
    }

    return (
      <View variants={['fullWidth', 'backgroundColor:neutral2', 'border-radius:smallish', 'marginBottom:3', 'padding:2', 'column']} style={{ minHeight: 144, maxHeight: 144 }}>

        <Text variants={['p2', 'color:neutral8']} text={`Author comments`} />
        <View variants={['fullWidth']} style={{ wordBreak: 'break-all', maxHeight: 40, textOverflow: 'ellipsis' }}>
          <TextEditor
            textEditorProps={{
              editor: editor,
              variants: ['reviewCommentModal', 'flat', 'editor:adaptiveHeight', 'bare'],
              styles: {
                editor: styles.editorWrap,
              },
            }}
            toolbarComponentProps={{
              editor: editor,
              excludeIds: ['image', 'link', 'fileComponent'],
              variants: ['reviewCommentModal', 'multiline'],
              hide: true,
            }}
          />
        </View>

        <View variants={['marginTop:3', 'row', 'gap:1', 'border-radius:nano']}>

          <Button
            variants={['backgroundColor:neutral1', 'small', 'text:p2', 'icon:primary5', 'text:primary5', 'flat:hover']}
            text={`Edit`}
            icon={'edit'}
            debugName={`Submit new Manuscript version Modal - Edit comment`}
            onPress={onOpenReviewCommentModal}
          />

          <Button
            text={`Delete`}
            variants={['backgroundColor:neutral1', 'small', 'text:p2', 'icon:destructive2', 'text:destructive2', 'flat:hover']}
            icon={'trash'}
            debugName={`Submit new Manuscript version Modal - Delete comment`}
            onPress={() => {
              getOsAlert('onDeleteComment', { onCancel: () => null, onDelete: () => onSaveNewComment('') })
            }}
          />

        </View>

      </View>
    )
  }

  const AttachmentItem = ({ item, index }) => {

    const isLocal = item instanceof File
    const fileCategoryValue = fileCategoryValues[index]

    const name = isLocal ? item?.name ?? (TypeGuards.isArray(item) && item[0]?.name) : item?.file?.name

    return (
      <View variants={['fullWidth', 'gap:2', 'marginBottom:2']}>

        <View variants={['paddingHorizontal:2', 'backgroundColor:neutral2', 'border-radius:small', 'justifySpaceBetween', 'fullWidth']}
          style={{ overflow: 'hidden' }}
        >

          <View variants={['gap:1', 'alignCenter', 'fullWidth']} style={{ overflow: 'hidden' }}>
            <Icon name='file' style={{ flexShrink: 0 }} size={20} debugName={'Attachment Item - file icon'} />
            <Text variants={[`p1`, 'color:neutral7', 'ellipsis']} text={name} />
          </View>

          <ActionIcon
            icon='x'
            debugName='Submit Manuscript Version Modal - close modal icon on press'
            variants={['transparent', 'iconSize:2', 'neutral8']}
            onPress={() => onRemoveAttachment(item)}
          />
        </View>

        <View style={styles.selectWrapper}>
          <Select
            debugName={`Item ${item} Select file category`}
            value={fileCategoryValue}
            options={file_categories}
            loadInitialValue
            variants={['fullWidth', 'noError', 'filePreview']}
            placeholder={'Select a category'}
            onValueChange={(category) => onSetCategory({ category, index })}
            searchable
          />
        </View>

      </View>
    )
  }

  const categories = file_categories?.filter?.(file => {
    return requiredCategories.some((category) => file[category] === true)
  })

  const missingCategories = categories?.filter((category) => {
    return attachments?.every((file) => {
      if (file?.category) {
        return file?.category !== category?.id
      } else {
        return file?.file_category !== category?.id
      }
    })
  })?.map((category) => category?.title)

  const mainDocCategory = PublicationUtils.findCategory({ categories: file_categories, key: 'refers_to_main_document' })
  const cleanVersionCategory = PublicationUtils.findCategory({ categories: file_categories, key: 'clean_version_of_revised_article_with_changes_accepted' })
  const responseToReviewerEditorCategory = PublicationUtils.findCategory({ categories: file_categories, key: 'response_to_reviewer_or_editor' })

  const mainDocFiles = PublicationUtils.filterFilesByCategory({ attachments, categoryValue: mainDocCategory?.value })
  const cleanVersionOfRevisedFiles = PublicationUtils.filterFilesByCategory({ attachments, categoryValue: cleanVersionCategory?.value })
  const responseToReviewerOrEditorFiles = PublicationUtils.filterFilesByCategory({ attachments, categoryValue: responseToReviewerEditorCategory?.value })

  const mainDocFilesExtValid = validateFileExtension(mainDocFiles[0]?.file.name)
  const cleanVersionOfRevisedFilesExtValid = validateFileExtension(cleanVersionOfRevisedFiles[0]?.file.name)
  const responseToReviewerOrEditorFilesExtValid = validateFileExtension(responseToReviewerOrEditorFiles[0]?.file.name)

  const hasDuplicateCategories = mainDocFiles?.length > 1 || cleanVersionOfRevisedFiles?.length > 1 || responseToReviewerOrEditorFiles?.length > 1

  const submitButtonDisabled = disableSubmit || fileCategoryValues?.some((item) => !item) || missingCategories?.length > 0 || hasDuplicateCategories || !mainDocFilesExtValid || !cleanVersionOfRevisedFilesExtValid || !responseToReviewerOrEditorFilesExtValid

  const openUploadModal = async () => {

    const result = await selectPublicationUploadFiles({
      showConfirmation: false,
      pickerDescription: null,
      pickerCategoriesFilter: {
        version: publication?.version,
        status: publication?.status,
      },
      showErrors: false,
      withCategory: false,
      validator: () => ({ message: '', valid: true }),
    })

    if (!result) return

    const files = result.attachments

    const newfiles = files.map((file) => {

      return {
        ...file,
        file_category: file.file_category?.value,
      }
    })

    setAttachments((state) => {

      return [...(state ?? []), ...newfiles]

    })
  }

  return (
    <>

      <View variants={['fullWidth', 'alignCenter', 'justifySpaceBetween']}>
        <Text variants={[`h3`]} text={'Submit new version'} />
        <ActionIcon
          icon='x'
          debugName='Submit Manuscript Version Modal - close modal icon on press'
          variants={['filterIcon', 'primary5', 'iconSize:3']}
          onPress={toggle}
        />
      </View>

      <Comment />

      <Dropzone
        ref={ref}
        variants={['marginBottom:3', 'fullWidth']}
        icon={attachments?.length ? null : 'add-file'}
        onDrop={() => { }}
        placeholder={`Please choose files to upload with your new version.`}
        noClick
        multiple
        styles={{
          'dropzone': {
            height: 'unset',
            flex: 1,
            justifyContent: attachments?.length > 0 ? 'flex-start' : 'center',
            overflow: 'auto',
          },
          'wrapper': {
            height: '100%',
            flex: 1,
            overflow: 'hidden',
          },
        }}
      >

        {attachments?.length > 0 && (
          <View style={styles.attachments}>
            {attachments?.map?.((item, index) => <AttachmentItem item={item} index={index} />)}
          </View>
        )}

        <Button
          debugName='Add more button on new version modal'
          text={attachments?.length ? 'Add more' : 'Add files'}
          icon='add-file'
          variants={['gap:1', 'flat', 'marginHorizontal:auto']}
          onClick={e => {
            e.stopPropagation()
            openUploadModal()
          }}
        />
      </Dropzone>

      {missingCategories?.length > 0 && (
        <View variants={['fullWidth', 'justifyCenter', 'textCenter']}>
          <Text variants={['p1', 'color:destructive2', 'fullWidth', 'paddingVertical:1']}>
            <Text
              variants={['p1', 'color:destructive2']}
              text={`Missing the following ${missingCategories?.length > 1 ? 'categories' : 'category'} for the files:`}
            />
            {missingCategories?.map?.((category) => (
              <Text
                text={`• "${category}"`}
                variants={['p1', 'color:destructive2']}
              />
            ))}
          </Text>
        </View>
      )}

      {(hasDuplicateCategories) && (
        <View variants={['fullWidth', 'justifyCenter', 'textCenter', 'column', 'paddingVertical:1']}>
          {mainDocFiles?.length > 1 && (
            <Text
              variants={['p1', 'color:destructive2']}
              text={`There cannot be more than one: "${mainDocCategory?.label}"`}
            />
          )}
          {cleanVersionOfRevisedFiles?.length > 1 && (
            <Text
              variants={['p1', 'color:destructive2']}
              text={`There cannot be more than one: "${cleanVersionCategory?.label}"`}
            />
          )}
          {responseToReviewerOrEditorFiles?.length > 1 && (
            <Text
              variants={['p1', 'color:destructive2']}
              text={`There cannot be more than one: "${responseToReviewerEditorCategory?.label}"`}
            />
          )}
        </View>
      )}

      <View variants={['fullWidth', 'justifyCenter', 'textCenter', 'column', 'paddingVertical:1']}>
        {(mainDocFiles?.length === 1 && !mainDocFilesExtValid) && (
          <Text
            variants={['p1', 'color:destructive2']}
            text={`"${mainDocCategory?.label}" must be a .pdf, .doc or .docx`}
          />
        )}
        {(cleanVersionOfRevisedFiles?.length === 1 && !cleanVersionOfRevisedFilesExtValid) && (
          <Text
            variants={['p1', 'color:destructive2']}
            text={`"${cleanVersionCategory?.label}" must be a .pdf, .doc or .docx`}
          />
        )}
        {(responseToReviewerOrEditorFiles?.length === 1 && !responseToReviewerOrEditorFilesExtValid) && (
          <Text
            variants={['p1', 'color:destructive2']}
            text={`"${responseToReviewerEditorCategory?.label}" must be a .pdf, .doc or .docx`}
          />
        )}
      </View>

      <Button
        variants={['border-radius:tiny', 'fullWidth', 'marginTop:1']}
        text={`Submit`}
        debugName={`Submit new Manuscript version Modal - Continue Button`}
        disabled={submitButtonDisabled}
        onPress={onSubmitVersion}
        debounce={1500}
      />

    </>
  )
})

const MAX_WIDTH = 200

const styles = variantProvider.createComponentStyle((theme) => ({
  attachments: {
    ...theme.presets.column,
    ...theme.presets.fullWidth,
  },
  selectWrapper: {
    maxWidth: MAX_WIDTH,
    ...theme.presets.fullWidth,
  },
  editorWrap: { //super hack
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    '-webkit-line-clamp': '2',
    textOverflow: 'ellipsis',
    maxHeight: 44,
    borderRadius: 0,
  },
}), true)

SubmitNewPublicationVersion.props({
  variants: ['replicaOverviewModal', 'overflow:visible'],
  showClose: false,
})
