import { useMemo } from 'react'
import { Button, OptionsDropDown, Text } from '@/components'
import { AppIcon, variantProvider } from '@/app'
import { formatMaximumNumber } from '@/utils'
import { TextProps } from '@codeleap/web'
import { AnyFunction } from '@codeleap/common'

type LimitOptionsProps = Partial<TextProps<'p'>> & {
  limit: number
  setLimit: AnyFunction
  numberOfItems: number
}

export function LimitOptions({ limit, setLimit, numberOfItems, ...props }: LimitOptionsProps) {
  const options = useMemo(() => {
    return [25, 50, 100, 500].map((value, index) => {
      const selected = limit === value

      return (
        <Button
          text={value.toString()}
          debugName={`limit-${value}-${index}`}
          onPress={() => setLimit(value)}
          selected={selected}
          variants={['roleSelector']}
          rightIcon={selected && 'check'}
          key={`limit-${value}-${index}`}
          style={styles.buttonOptions}
        />
      )
    })
  }, [limit])

  return (
    <Text css={styles.wrapper} {...props}>
      Showing{'\u00A0'}
      <OptionsDropDown
        debugName='limit selector'
        tooltipProps={{
          closeOnClickOutside: true,
          content: options,
          styles: {
            'content:bottom': styles.tooltipBottom,
            arrow: styles.tooltipArrow,
          },
        }}
        variants={['optionText:primary5', 'optionLines:none', 'textIcon:reverse', 'textIcon:spacing1']}
        styles={{ textIconWrapper: styles.textIconWrapper }}
        text={limit.toString()}
        icon={'chevron-down' as AppIcon}
        options={[]}
      />
      {'\u00A0'}
      per page,{'\u00A0'}
      <Text variants={['p1', 'bold', 'color:neutral9']} text={formatMaximumNumber(numberOfItems, 999)} />
      {'\u00A0'}submissions in total
    </Text>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    wrapper: {
      ...theme.presets.row,
      ...theme.presets.alignCenter,
      color: theme.colors.neutral8,
      height: theme.spacing.value(4),
      ...theme.presets.alignCenter,
      flexWrap: 'wrap',
    },
    tooltipBottom: {
      zIndex: 99999,
      ...theme.presets.elevated,
      borderRadius: theme.borderRadius.medium,
      ...theme.spacing.padding(2),
      backgroundColor: theme.colors.neutral1,
      ...theme.spacing.marginTop(0.5),
    },
    tooltipArrow: {
      display: 'none',
      width: 0,
      height: 0,
    },
    buttonOptions: {
      ...theme.spacing.gap(1),
      height: theme.spacing.value(5),
    },
    textIconWrapper: {
      flexDirection: 'row-reverse',
      cursor: 'pointer',
      ...theme.spacing.gap(1),
      ...theme.spacing.paddingHorizontal(2),
      backgroundColor: theme.colors.neutral2,
      height: theme.spacing.value(4),
      ...theme.presets.center,
      borderRadius: theme.borderRadius.nano,
    },
  }),
  true,
)
