import { FilePreview } from './Forms/FilePreview'
import { PublicationHeader } from './PublicationHeader'
import { PublicationToast } from './PublicationToast'
import { PublicationHistory, historyMessages } from './History'
import { PublicationBanner } from './PublicationBanner'
import { IdColumn } from './List/IdColumn'
import { NameColumn } from './List/NameColumn'
import { EditorColumn } from './List/EditorColumn'
import { StatusColumn } from './List/StatusColumn'
import { JournalColumn } from './List/JournalColumn'
import { ManuscriptType } from './List/ManuscriptType'
import { PlagiarismChecker } from './Plagiarism'

export const Publication = {
  FilePreview,
  Header: PublicationHeader,
  Toast: PublicationToast,
  History: PublicationHistory,
  Banner: PublicationBanner,
  PlagiarismChecker,
}

export const PublicationColumns = {
  IdColumn,
  NameColumn,
  EditorColumn,
  StatusColumn,
  JournalColumn,
  ManuscriptType,
}

export * from './Forms/usePublicationForm'
export * from './Forms/PublicationForm'
export { historyMessages }
