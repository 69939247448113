import { PublicationStatus } from '@/types'

export const statusColorMap: Record<keyof typeof PublicationStatus, string> = {
  saved_in_drafts: 'darkBlue',
  submitted_for_review: 'blue',
  under_review: 'blue',
  pending_review: 'orange',
  reviewers_invited_accepted: 'orange',
  review_submitted: 'blue',
  rejected: 'red',
  accepted: 'green',
  revision_requested: 'yellow',
  revision_in_drafts: 'darkBlue',
  revision_completed: 'blue',
  rejected_transfer: 'red',
  rejected_resubmit: 'red',
  withdraw: 'darkBlue',
}
