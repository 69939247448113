import { React } from '@/app'
import { Text, Avatar, Icon, Touchable, View, ReviewerDetails } from '@/components'
import { AppStatus } from '@/redux'
import { APIClient } from '@/services'
import { DecisionReviewer } from '@/types'

export type NamePreviewProps = {
  reviewer: DecisionReviewer
  publicationId: number
  color?: string
  showMoreInfoIcon?: boolean
  position: number
  isEditor: boolean
  onCloseInfo?: () => void
  isAi?: boolean
}

export const NamePreview = (props: NamePreviewProps) => {
  const { reviewer, publicationId, position, showMoreInfoIcon = true, isEditor: _isEditor, isAi, onCloseInfo = () => null } = props

  const { isAuthor, isEditor, isPublisher } = APIClient.Session.usePermissions()

  const wrapperVariants = ['row', 'gap:0.75', 'alignCenter', 'justifyStart', 'fullWidth']

  const Wrapper = (showMoreInfoIcon && (isEditor || isPublisher) ? Touchable : View)

  const wrapperProps = showMoreInfoIcon ? {
    variants: wrapperVariants,
    onPress: () => {
      AppStatus.clearModals()
      ReviewerDetails.request({ data: { id: reviewer?.reviewer_id, publication: publicationId }}).then(onCloseInfo)
    },
  } : { variants: wrapperVariants }

  let reviewerDescription = !isAi ? '| Reviewer ' + (position + 1) : ''
  if (_isEditor) reviewerDescription = ''
  else if (isAuthor) reviewerDescription = `${position + 1}`

  let name = 'Reviewer'
  if (_isEditor) name = 'Editor'
  else if (!isAuthor) name = reviewer.reviewer_name

  return (
    <Wrapper {...wrapperProps} >

      <Avatar
        debugName='overviewModal'
        name={name}
        variants={['small']}
        firstNameOnly
        styles={{ initials: { fontSize: 8 }, icon: isAi && { color: '#fff' }}}
        icon={isAi && 'bot'}
        color={isAi && 'black'}
      />

      <Text variants={['p2']} text={name} />
      <Text variants={[`p2`, 'noWrap']} text={reviewerDescription} />

      {(showMoreInfoIcon && !isAuthor && !_isEditor) && <Icon name='info' variants={['size:1', 'neutral8']} />}
    </Wrapper>
  )
}
