import { variantProvider, AppForms } from '@/app'
import { View, Text, TextInput, ActionIcon, SelectForm } from '@/components'
import { FormComponentProps } from '../../BaseForm'
import { PossibleReviewer, ScientificTag } from '@/types'
import { useMemoizedForm } from '@/utils'
import { FormRef } from '..'
import { forwardRef, useCallback, useImperativeHandle } from 'react'

type FieldOptions = 'full_name' | 'organisation' | 'email' | 'tags'

type ReviewerBaseFormProps = FormComponentProps<Partial<PossibleReviewer>> & {
  scientificTags: ScientificTag[]
  disabled?: boolean
}

export const ReviewerBaseForm = forwardRef<FormRef, ReviewerBaseFormProps>((props, ref) => {
  const { value, deleteForm, scientificTags, disabled = false, index, isFirst } = props
  const form = useMemoizedForm(AppForms.reviewerForm, { validateOn: 'change', initialState: { ...value, index }})
  const showDeleteIcon = !(isFirst || disabled)

  const handleForm = useCallback((field: FieldOptions) => {
    const fieldFormError = form.fieldErrors[field]
    const fieldValidation = form.validateField(field)

    return {
      ...form.register(field),
      id: `reviewer_${field}_input_${index}`,
      _error: fieldFormError && !fieldValidation.valid ? fieldValidation.message ?? fieldFormError : '',
      disabled,
    }
  }, [form])

  useImperativeHandle(ref, () => ({
    getFormValue: () => form.values,
    validateForm: () => form.validateAll(true),
  }), [form])

  return (
    <View style={styles.wrapper} id={`suggestedReviewerForm_${index}`}>
      <View variants={['row', 'alignCenter', 'justifySpaceBetween']}>
        <Text text={'Reviewer'} variants={['h5', 'color:neutral8']} />
        {showDeleteIcon && (
          <ActionIcon
            icon='trash'
            variants={['destructive:minimal', 'normalize']}
            debugName='Remove reviewer form'
            onPress={() => deleteForm(index, value)}
          />
        )}
      </View>

      <View style={styles.grid}>
        <TextInput {...handleForm('full_name')} />
        <TextInput {...handleForm('organisation')} />
        <TextInput {...handleForm('email')} />
        <SelectForm
          {...handleForm('tags')}
          options={scientificTags}
          leftIcon={{ name: 'flask-conical' }}
          styles={{ leftIconTouchableWrapper: styles.leftIconTouchableWrapper }}
        />
      </View>
    </View>
  )
})

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    grid: {
      display: 'grid',
      // prevent grid from overflowing horizontally when large texts are typed on the text input
      gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
      columnGap: theme.spacing.value(5),
      rowGap: theme.spacing.value(3),
      [theme.media.down('small')]: {
        // prevent grid from overflowing horizontally when large texts are typed on the text input
        gridTemplateColumns: 'minmax(0, 1fr)',
      },
    },
    wrapper: {
      ...theme.presets.column,
      ...theme.spacing.gap(3),
      ...theme.spacing.padding(3),
      ...theme.border.neutral5({ width: 1 }),
      // @ts-ignore
      borderRadius: theme.borderRadius.large,
    },
    leftIconTouchableWrapper: {
      ...theme.spacing.marginRight(1),
    },
  }),
  true,
)
