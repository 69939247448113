import { View, Page, Image } from '@/components'
import { React, variantProvider, AppImages, Theme } from '@/app'
import { onUpdate } from '@codeleap/common'
import { useLocation } from '@reach/router'
import { Navigation } from '@/utils'
import { APIClient } from '@/services'

const BREAKPOINT = 'mid'
const notAuthenticatedScreens = ['/auth/login/', '/auth/signup/', '/auth/reset/']

export const AuthWrapper = ({ title, image = AppImages.placeholderBg, children }) => {
  const { isLoggedIn } = APIClient.Session.useSession()
  const { pathname } = useLocation()

  onUpdate(() => {
    const isNotAuthenticatedRoute = notAuthenticatedScreens.includes(pathname)

    if (isLoggedIn && isNotAuthenticatedRoute) {
      Navigation.navigate('Home')
    }
  }, [pathname])

  return (
    <Page centerContent={false} pageTitle={title} showHeader={false}>
      <View style={styles.wrapper}>
        <View style={styles.leftContainer}>
          <Image
            source={image}
            style={styles.image}
            objectFit='cover'
          />

          <Image
            source={AppImages.LogoWhite}
            style={styles.logo}
            objectFit='cover'
          />
        </View>

        <View style={styles.rightContainer}>
          {children}
        </View>
      </View>
    </Page>
  )
}

const LOGO_WIDTH = 300

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    minHeight: '90vh',
    ...theme.presets.fullWidth,
    ...theme.presets.row,
    overflow: 'hidden',

    [theme.media.down(BREAKPOINT)]: {
      flexDirection: 'column',
      overflow: 'auto',
    },
  },
  leftContainer: {
    ...theme.presets.flex,
    ...theme.presets.alignCenter,
    backgroundColor: theme.colors.primary1,
    [theme.media.down(BREAKPOINT)]: {
      ...theme.presets.justifyCenter,
    },
    position: 'relative',
  },
  rightContainer: {
    maxWidth: 640,
    ...theme.presets.fullWidth,
    ...theme.spacing.padding(5),
    ...theme.presets.center,

    [theme.media.down(BREAKPOINT)]: {
      width: '100%',
      maxWidth: '100vw',
      ...theme.spacing.padding(2),
      ...theme.spacing.paddingTop(4),
    },
  },
  image: {
    ...theme.presets.full,
  },
  logo: {
    position: 'absolute',
    width: LOGO_WIDTH,
    top: 30,
    left: 30,
  },
}), true)
