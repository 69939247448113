import { BlindfoldedFiles, ReplicaOverviewModal, ReviewerQuestionsForm, ReviewsOverview, Text, usePublicationForm } from '@/components'
import { APIClient } from '@/services'
import { useSession } from '@/services/api/session'
import {
  HistoryEditorDecision,
  HistoryReview,
  HistoryStatusChange,
  HistoryVersion,
  MetadataChange,
  PublicationHistory,
  PublicationStatus,
  RedactedFilesHistory,
  ReviewerAcceptedHistory,
  TransferHistory,
} from '@/types'
import { humanizeSnakeCase } from '@/utils'

const readableStatus = {
  ...PublicationStatus,
  rejected: 'Rejected',
  accepted: 'Accepted',
  revision_requested: 'Revised',
}

const getColor = (text: keyof typeof readableStatus | string) => {
  let color = 'neutral9'
  switch (text) {
    case 'rejected':
    case 'rejected_transfer':
      color = 'destructive2'
      break
    case 'accepted':
      color = 'positive3'
      break
    case 'revision_requested':
      color = 'alert2'
      break

    default:
      color = 'neutral9'
      break
  }
  return color
}

const readHistoryBadge = async (badge) => {
  if (!badge) return null
  if (badge?.id) {
    await APIClient.Badges.readBadge({ type: 'badge_id', id: badge?.id })
  }
}

/*@ts-ignore*/
const renderColoredText = (text = '', capitalize = true) => (
  <Text variants={[`color:${getColor(text)}`, 'semiBold', capitalize && 'capitalize', 'inline']}>
    {readableStatus?.[text] ? readableStatus?.[text] : humanizeSnakeCase(text)}
  </Text>
)

export function historyMessages() {

  const handleGoToReviewers = usePublicationForm(v => v.handleGoToReviewers)
  const { title, journal } = usePublicationForm(v => v.publication)

  const { isAuthor } = useSession()

  return {
    new_editor_decision: {
      name: (item: PublicationHistory<HistoryEditorDecision>) => (
        <Text variants={['p1', 'row', 'noWrap']}>
          {renderColoredText('New editor decision')}
          {'\u00A0'}submitted, recommending manuscript to be{'\u00A0'}
          {renderColoredText(item?.changes?.status)}
        </Text>
      ),
      icon: 'eyeglass-2',
      onPress: async (params) => {
        const badge = params?.badges[0]
        ReviewsOverview.open({ data: { ...params, version: params?.version || params?.changes?.version }})
        readHistoryBadge(badge)
        return
      },
    },
    article_status_change: {
      name: (item: PublicationHistory<HistoryStatusChange>) => (
        <Text variants={['p1', 'row', 'noWrap']}>
          {renderColoredText('Manuscript status')}
          {'\u00A0'}changed from{'\u00A0'}
          {renderColoredText(item?.changes?.old_status)}
          {'\u00A0'}to{'\u00A0'}
          {renderColoredText(item?.changes?.new_status)}
        </Text>
      ),
      icon: 'license',
    },
    new_review: {
      name: (item: PublicationHistory<HistoryReview>) => {
        const reviewerName = !isAuthor || item?.changes?.is_ai ? item?.changes?.reviewer_name : 'New review'
        return (
          <Text variants={['p1', 'row', 'noWrap']}>
            {renderColoredText(reviewerName, false)}
            {'\u00A0'}{isAuthor && !item?.changes?.is_ai ? 'submitted, the recommendation is' : 'submitted a new review, the recommendation is'}{'\u00A0'}
            {renderColoredText(item?.changes?.recommendation)}
          </Text>
        )
      },
      icon: 'under-review',
      onPress: async (params) => {
        const badge = params?.badges[0]
        ReviewerQuestionsForm.open({ ...params, reviewID: params?.review, articleName: title, journalName: journal?.title })
        readHistoryBadge(badge)
      },
    },
    new_version: {
      name: (item: PublicationHistory<HistoryVersion>) => (
        <Text variants={['p1', 'row', 'noWrap']}>
          {renderColoredText(`Manuscript version ${item?.version}`)}
          {'\u00A0'}submitted by the author
        </Text>
      ),
      icon: 'file-plus',
      onPress: async (params) => {

        ReplicaOverviewModal.open({
          publicationId: params?.article,
          version: params?.version,
        })

        const badge = params?.badges[0]
        readHistoryBadge(badge)
      },
    },
    article_metadata_changes: {
      name: ({ changes }: PublicationHistory<MetadataChange>) => {
        const values = eval(changes.fields.replace(/None/g, 'null'))
        const role = values[0]?.role
        const fields = values.reduce((acc, curr) => acc + curr.field + ', ', '').slice(0, -2)

        return (
          <Text variants={['p1', 'inline']}>
            Manuscript details updated by{'\u00A0'}
            {renderColoredText(role)}
            {'\u00A0'}- fields changed:{'\u00A0'}
            {renderColoredText(fields)}
          </Text>
        )
      },
      icon: 'under-review',
    },
    reviewer_invite_accepted: {
      name: (item: PublicationHistory<ReviewerAcceptedHistory>) => {
        const reviewerName = isAuthor ? 'A new reviewer' : item?.changes?.reviewer_name || 'Reviewer'

        return (
          <Text variants={['p1', 'row', 'noWrap']}>
            {renderColoredText(reviewerName)}
            {'\u00A0'}{isAuthor ? 'has accepted the invite to review your submission.' : 'accepted the invite to be a reviewer'}
          </Text>
        )
      },
      icon: 'under-review',
      onPress: async (params) => {
        const badge = params?.badges[0]
        readHistoryBadge(badge)
        if (!isAuthor) {
          handleGoToReviewers()
        }
        return
      },
    },
    transfer_publication: {
      name: (item: PublicationHistory<TransferHistory>) => {
        let title = item?.changes?.article_title || ''
        if (title?.length > 25) {
          title = title.substring(0, 25) + '...'
        }
        return <Text variants={['p1', 'row', 'noWrap']}>
          {renderColoredText(title)}
          {'\u00A0'}was{'\u00A0'}
          {renderColoredText(item?.changes?.new_status)}
          {'\u00A0'}from{'\u00A0'}
          {renderColoredText(item?.changes?.old_journal)}
          {'\u00A0'}to{'\u00A0'}
          {renderColoredText(item?.changes?.new_journal)}
        </Text>
      },
      icon: 'arrows-diff',
    },
    redacted_files_uploaded: {
      name: (item: PublicationHistory<RedactedFilesHistory>) => {
        return <Text variants={['p1', 'row', 'noWrap']}>
          {renderColoredText('Blinded review files')}
          {'\u00A0'}for manuscript version{'\u00A0'}
          {renderColoredText(String(item?.changes?.version || 1))}
          {'\u00A0'}have been uploaded{'\u00A0'}
        </Text>
      },
      icon: 'license',
      onPress: (item: PublicationHistory<RedactedFilesHistory>) => BlindfoldedFiles.open({ files: item?.changes?.files, version: item?.changes?.version }),
    },
  }
}
