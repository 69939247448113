import { api } from "@/app"
import { getFirebase } from "@/services/firebaseApp"
import { useSession } from "./useSession"
import { AppStatus } from "@/redux"
import { navigate } from "gatsby"



const getCustomToken = async (email: string) => {
  const response = await api.post<{ token: string }>(`/profiles/custom_token/`, {
    email
  })

  return response.data.token
}

export function useImpersonation(){
  const { logout } = useSession()

  const impersonate = async (email: string) => {
    AppStatus.set('loading')
    try {
      const token = await getCustomToken(email)
  
      const firebase = await getFirebase()
      
      await logout()
    
      await firebase.auth().signInWithCustomToken(token)

      navigate('/manuscripts')

    } catch(e) {
      
    }
    AppStatus.set('idle')   
  }

  return impersonate
}