import { Theme } from '@/app'
import { PublicationStatus } from '@/types'

export type ChartColumnColors = Partial<Record<'color' | 'textColor' | 'icon' | 'label', string>>

const defaultStatus: ChartColumnColors = {
  color: '#59759E',
  textColor: Theme.colors.light.neutral1,
  icon: 'archive',
  label: '',
}

export const statusChartColors: { [p: string]: ChartColumnColors } = {
  'accepted': {
    color: Theme.colors.light.green1,
    textColor: Theme.colors.light.neutral1,
    icon: 'check-circle',
    label: PublicationStatus.accepted,
  },
  'under_review': {
    color: Theme.colors.light.blue4,
    textColor: Theme.colors.light.neutral1,
    icon: 'under-review',
    label: PublicationStatus.under_review,
  },
  'revision_requested': {
    color: Theme.colors.light.yellow1,
    textColor: Theme.colors.light.neutral10,
    icon: 'eyeglass-2',
    label: PublicationStatus.revision_requested,
  },
  'rejected': {
    color: Theme.colors.light.red1,
    textColor: Theme.colors.light.neutral1,
    icon: 'x-circle',
    label: PublicationStatus.rejected,
  },
  'revision_completed': {
    color: Theme.colors.light.blue4,
    textColor: Theme.colors.light.neutral1,
    icon: 'shield-check',
    label: PublicationStatus.revision_completed,
  },
  'revision_in_drafts': {
    ...defaultStatus,
    color: Theme.colors.light.blue3,
    textColor: Theme.colors.light.neutral1,
    label: PublicationStatus.revision_in_drafts,
  },
  'saved_in_drafts': {
    ...defaultStatus,
    color: Theme.colors.light.blue3,
    textColor: Theme.colors.light.neutral1,
    label: PublicationStatus.saved_in_drafts,
  },
  'rejected_transfer': {
    ...defaultStatus,
    color: Theme.colors.light.red1,
    textColor: Theme.colors.light.neutral1,
    label: PublicationStatus.rejected_transfer,
  },
  'rejected_resubmit': {
    ...defaultStatus,
    color: Theme.colors.light.red1,
    textColor: Theme.colors.light.neutral1,
    label: PublicationStatus.rejected_resubmit,
  },
  'submitted_for_review': {
    ...defaultStatus,
    color: Theme.colors.light.blue4,
    textColor: Theme.colors.light.neutral1,
    label: PublicationStatus.submitted_for_review,
  },
  'pending_review': {
    ...defaultStatus,
    color: Theme.colors.light.orange1,
    textColor: Theme.colors.light.neutral10,
    label: PublicationStatus.pending_review,
  },
  'reviewers_invited_accepted': {
    ...defaultStatus,
    color: Theme.colors.light.orange1,
    textColor: Theme.colors.light.neutral10,
    label: PublicationStatus.reviewers_invited_accepted,
  },
  'review_submitted': {
    ...defaultStatus,
    color: Theme.colors.light.blue4,
    textColor: Theme.colors.light.neutral1,
    label: PublicationStatus.review_submitted,
  },
  'withdraw': {
    ...defaultStatus,
    color: Theme.colors.light.blue3,
    textColor: Theme.colors.light.neutral1,
    label: PublicationStatus.withdraw,
  },
} as const
