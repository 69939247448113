import { modal, useSorter, useSortModal } from '@/utils'
import React from 'react'
import { Content, Item } from './components'
import { FilterDataItem, SortModalProps } from './Sort'
import { LocalStorageKeys } from '@/app'
import { manuscriptFilters, manuscriptOptionsLabels } from '../Dashboard'
import { TypeGuards } from '@codeleap/common'

type Keys = keyof typeof LocalStorageKeys

export type ManuscriptModalProps = SortModalProps & React.PropsWithChildren<{}>

type UseManuscriptSorterProps = {
  key: Keys
  options?: string[]
}

const formatOptions = (options: string[]) => {
  return options?.flatMap((option) => {
    const isDescending = option.startsWith('-')
    const cleanOption = isDescending ? option.slice(1) : option

    const createOption = (value: string, order: 'ascending' | 'descending') => ({
      value,
      label: `${manuscriptOptionsLabels[cleanOption]} ${order}`,
      buttonProps: {
        icon: order === 'ascending' ? 'arrow-up' : 'arrow-down',
      },
    })

    if (isDescending) {
      return [createOption(`-${option}`, 'descending')]
    }

    return [
      createOption(option, 'ascending'),
      createOption(`-${option}`, 'descending'),
    ]
  })
}

export const ManuscriptSortModal = modal<ManuscriptModalProps, FilterDataItem[]>().content((props) => {
  const options = props?.options ? formatOptions(props.options) : formatOptions(manuscriptFilters)

  const sortParams = useSortModal({ options, initialState: props.initialState, request: props.request, limit: props.limit })

  return <>
    <Content
      title='Region region test'
      renderItem={Item}
      {...sortParams}
      {...props}
    />
  </>
})

export const useManuscriptSorter = (props: UseManuscriptSorterProps) => {
  const { key, options = manuscriptFilters } = props

  const sorter = useSorter({
    key,
    options,
    modal: ManuscriptSortModal,
    limit: 1,
    hasScroll: true,
    sendOptionsToModal: true,
  })

  return {
    sorter: {
      ...sorter,
      currentOption: formatOptions(!Array.isArray(sorter.sortBy) ? [] : sorter.sortBy)?.[0], // previously it was an object, so prod user still have it
    },
  }
}

ManuscriptSortModal.props({
  variants: ['sectionFiltersModal', 'centered'],
  title: 'Sorting',
})

