import { I18N, IsDevEnv } from '@/app'
import { createForm, yup } from '@codeleap/common'
import { fakeProfile, testerOptions, testers } from '../testers'
export * from '../testers'

const initialState = IsDevEnv ? testers.default : undefined

export const orcidFieldEmptyMaskValue = '____-____-____-____'
const orcidFieldMask = '0000-0000-0000-000X'

export const login = () => createForm('login', {
  email: {
    type: 'text',
    placeholder: 'email@email.com',
    validate: yup
      .string()
      .required()
      .email(),
    leftIcon: { name: 'mail' },
    // defaultValue: initialState?.email,
  },
  password: {
    type: 'text',
    password: true,
    validate: yup
      .string()
      .required()
      .min(6)
      .max(128),
    leftIcon: { name: 'key' },
    placeholder: 'My secret password',
    // defaultValue: initialState?.password,
  },
  remember: {
    type: 'checkbox',
    label: I18N.t('form.label.terms'),
    defaultValue: true,
  },
  tester: {
    type: 'select',
    label: 'Tester',
    options: testerOptions,
    // defaultValue: 'default',
  },
})

export const impersonation = () => {
  const loginForm = login()
  return createForm('impersonation', {
    email: loginForm.config.email,
  })
}

export const createSignup = (withPassword = false) => {
  const loginForm = login()

  return createForm('signup', {
    email: {
      ...loginForm.config.email,
      // defaultValue: fakeProfile.email,
    },
    password: {
      ...loginForm.config.password,
      // defaultValue: fakeProfile.password,
      validate: withPassword ? loginForm.config.password.validate : undefined,
    },
    first_name: {
      type: 'text',
      label: 'First name',
      placeholder: 'Enter your first name',
      defaultValue: fakeProfile.first_name,
      validate: yup.string().required('This is a required field').min(3, 'Minimum of 3 characters')
        .max(30)
        .nullable(),
    },
    last_name: {
      type: 'text',
      label: 'Surname',
      placeholder: 'Enter your surname',
      defaultValue: fakeProfile.last_name,
      validate: yup.string().required('This is a required field').min(3, 'Minimum of 3 characters')
        .max(30)
        .nullable(),
    },
    title: {
      type: 'select',
      label: 'Title',
      placeholder: 'Enter your title',
      searchable: true,
      validate: (value) => {
        return { valid: typeof value !== 'string', message: 'Title is required' }
      },
    },
    repeatPassword: {
      ...loginForm.config.password,
      label: 'Confirm Password',
      // defaultValue: fakeProfile.repeatPassword,
      validate: withPassword ? (repeatPassword, { password }) => {
        const isValid = repeatPassword === password
        return {
          valid: isValid,
          message: isValid ? '' : "Passwords don't match",
        }
      } : null,
    },
    remember: {
      ...loginForm.config.remember,
    },
    acceptance: {
      type: 'checkbox',
      // defaultValue: false,
      validate: (val) => ({ valid: val, message: 'You must agree with the terms' }),
    },
    avatar: {
      type: 'file',
    },
    tester: loginForm.config.tester,
  })
}

export const signup = () => createSignup(true)
export const socialSignup = () => createSignup(false)

export const forgotPassword = () => {
  const loginForm = login()
  return createForm('forgotPassword', {
    email: loginForm.config.email,
    tester: loginForm.config.tester,
  })
}

export const aboutYou = () => {
  return createForm('aboutYou', {
    gender: {
      type: 'select',
      label: 'Gender',
      placeholder: 'Select a gender',
      searchable: true,
      validate: (value) => {
        return { valid: typeof value === 'number', message: 'Gender is required' }
      },
    },
    telephone_number: {
      type: 'text',
      label: 'Telephone number (optional)',
      placeholder: 'Enter your telephone number',
      // defaultValue: fakeProfile.phone,
      validate: (value) => {
        const masklessValue = value?.replace(/\D/g, '')
        return {
          valid: masklessValue?.length === 0 || masklessValue === value?.replaceAll('-', ''),
          message: 'This field is invalid or incomplete',
        }
      },
    },
    mobile_number: {
      type: 'text',
      label: 'Mobile number (optional)',
      placeholder: 'Enter your mobile number',
      validate: (value) => {
        const masklessValue = value?.replace(/\D/g, '')
        return {
          valid: masklessValue?.length === 0 || masklessValue === value?.replaceAll('-', ''),
          message: 'This field is invalid or incomplete',
        }
      },
      defaultValue: fakeProfile.mobile,
    },
    region: {
      type: 'select',
      label: 'Country',
      placeholder: 'Select a country',
      searchable: true,
      validate: (value) => {
        return { valid: typeof value === 'number', message: 'Country is required' }
      },
    },
    degree: {
      type: 'text',
      label: 'Degree',
      placeholder: 'Enter your degree',
      validate: yup.string().required('This is a required field').min(3, 'Minimum of 3 characters')
        .max(256)
        .nullable(),
      defaultValue: fakeProfile.degree,
    },
  })
}

export const aboutYourWork = () => {
  return createForm('aboutYourWork', {
    address: {
      type: 'text',
      label: 'Address',
      placeholder: 'Select your address',
      validate: yup.string().required('This is a required field').min(3)
        .max(256)
        .nullable(),
      defaultValue: fakeProfile.address,
    },
    postcode: {
      type: 'text',
      label: 'Post code',
      placeholder: 'Enter your post code',
      validate: yup.string().required('This is a required field').max(256)
        .nullable(),
      defaultValue: fakeProfile.postcode,
    },
    position: {
      type: 'text',
      label: 'Position',
      placeholder: 'Enter your position',
      validate: yup.string().required('This is a required field').min(3, 'Minimum of 3 characters')
        .max(256)
        .nullable(),
      defaultValue: fakeProfile.position,
    },
    department: {
      type: 'text',
      label: 'Department',
      placeholder: 'Enter your department',
      validate: yup.string().required('This is a required field').min(3, 'Minimum of 3 characters')
        .max(256)
        .nullable(),
      defaultValue: fakeProfile.department,
    },
    organisation: {
      type: 'text',
      label: 'Organisation/Institution',
      // defaultValue: fakeProfile.organisation,
      placeholder: 'Enter your organisation/institution',
      validate: yup.string().nullable().min(3, 'Minimum of 3 characters')
        .max(60)
        .required(),
      leftIcon: { name: 'building' },
    },
    tags: {
      type: 'select',
      label: 'Scientific fields',
      placeholder: 'Search',
      leftIcon: { name: 'flask-conical' },
      validate: (value) => ({ valid: value?.length > 0, message: 'Scientific fields are required' }),
      searchable: true,
    },
  })
}

export const editProfile = () => {
  const signupForm = signup()
  const aboutYouForm = aboutYou()
  const aboutYourWorkForm = aboutYourWork()

  // TODO - put the other fields here
  return createForm('editProfile', {
    email: signupForm.config.email,
    avatar: signupForm.config.avatar,
    email_notifications: {
      type: 'checkbox',
    },

    first_name: signupForm.config.first_name,
    last_name: signupForm.config.last_name,
    title: signupForm.config.title,

    gender: aboutYouForm.config.gender,
    telephone_number: aboutYouForm.config.telephone_number,
    mobile_number: aboutYouForm.config.mobile_number,
    region: aboutYouForm.config.region,
    degree: aboutYouForm.config.degree,

    address: aboutYourWorkForm.config.address,
    postcode: aboutYourWorkForm.config.postcode,
    position: aboutYourWorkForm.config.position,
    department: aboutYourWorkForm.config.department,
    organisation: aboutYourWorkForm.config.organisation,
    tags: aboutYourWorkForm.config.tags,
    orcid: {
      type: 'text',
      label: 'ORCID',
      defaultValue: fakeProfile.orcid,
      masking: {
        type: 'custom',
        options: {
          placeholder: 'Enter your ORCID',
          mask: orcidFieldMask,
          formatChars: {
            'X': '[A-Za-z]',
            '0': '[0-9]',
          },
        },
      },
      validate: (value) => {
        const orcidValueMasklessLength = orcidFieldMask.replace(/-/g, '').length
        const masklessValue = value?.replace(/-/g, '')
        const validMaskRegex = /[A-Za-z]$/
        const isValueComplete = masklessValue?.length === orcidValueMasklessLength
        const isValidValue = validMaskRegex.test(value)
        return { valid: isValueComplete && isValidValue, message: '' }
      },
    },
  })
}
