import { createForm, yup } from '@codeleap/common'

export const journalQuestionValidationRules = {
  question: {
    min: 15,
    max: 150,
  },
}

export const journalQuestionForm = () => createForm('journalQuestionForm', {
  question: {
    type: 'text',
    validate: yup.string().notRequired().min(journalQuestionValidationRules.question.min)
      .max(journalQuestionValidationRules.question.max),
  },
})
