import { TypeGuards, useCallback, useImperativeHandle, useMemo, useRef } from '@codeleap/common'
import { usePublicationForm } from '@/components'
import { BaseForm } from '../../BaseForm'
import { APIClient } from '@/services'
import { PublicationAuthor } from '@/types'
import { PublicationUtils, useSyncedState } from '@/utils'
import { hasAuthorsChanges, isAuthorsValid, validateAuthorOrder } from './utils'
import { NestedForms } from '..'
import { AuthorBaseForm } from './AuthorBaseForm'

const debug = false as const

export const AuthorForm = () => {
  const authors = usePublicationForm(v => v.authors)
  const publication = usePublicationForm(v => v.publication)
  const inDrafts = usePublicationForm(v => v.isSaved_in_drafts)
  const ref = usePublicationForm(v => v.modulesRefs.authors)

  const { delete: deleteAuthor } = APIClient.Publications.authorsManager.useDelete()
  const { scientificTags, profile, regions } = APIClient.Session.useSession()

  const initialState: Partial<PublicationAuthor>[] = useMemo(() => {
    if (debug) console.log('initial state', authors)

    if (authors?.items?.length) return authors.items
    if (profile && publication) {
      const _profile = publication.profile ?? profile

      return [{
        full_name: `${_profile.first_name} ${_profile.last_name}`,
        email: _profile.email,
        tags: _profile.tags,
        address: _profile.address,
        organisation: _profile.organisation,
        region: _profile.region,
        order: 1,
      }]
    }
    return [PublicationUtils.authorInitialState]
  }, [authors])

  const [forms, setForms] = useSyncedState(initialState, debug)

  const formsRefs = useRef<NestedForms>({})

  const updateForms = useCallback(() => {
    const newForms = Object.values(formsRefs.current)?.map(f => f?.getFormValue?.())?.filter(Boolean)
    setForms(newForms)
    return newForms
  }, [formsRefs])

  const showError = useCallback(() => {
    Object.values(formsRefs.current)?.forEach(f => f?.validateForm?.())
  }, [formsRefs])

  const onClear = useCallback(() => {
    setForms([PublicationUtils.authorInitialState])
  }, [])

  const handleInitialOrder = useCallback(() => {
    const values = updateForms()
    setForms([...values, { ...PublicationUtils.authorInitialState, order: values?.length + 1 }])
  }, [updateForms, setForms])

  const onSave = useCallback(async () => {
    const values = updateForms()
    if (hasAuthorsChanges(values, initialState)) {
      const trimmedValues = values.map(item => ({
        ...item,
        contribution: item.contribution?.trim() || item.contribution,
        email: item.email?.trim() || item.email,
        full_name: item.full_name?.trim() || item.full_name,
        organisation: item.organisation?.trim() || item.organisation,
      }))

      await APIClient.Publications.createAuthors(Number(publication.id), trimmedValues)
    }
  }, [updateForms, initialState])

  const onValidate = () => {
    const values = updateForms()
    const { isValid, index } = isAuthorsValid(values)
    const orderIsValid = validateAuthorOrder(values)

    let errorMessage: string
    if (!orderIsValid) errorMessage = 'Author position values must be unique and form a sequence.'

    const hasIndex = TypeGuards.isNumber(index)
    const anchor = hasIndex ? `authorForm_${index}` : PublicationUtils.fieldAnchors.authors
    const scrollProps = hasIndex ? { offset: -16 } : {}

    return { isValid: isValid && orderIsValid, errorMessage, anchor, scrollProps }
  }

  useImperativeHandle(ref, () => ({
    onValidate,
    onSave,
    onClear,
    showError,
    hasChanges: hasAuthorsChanges(forms, initialState),
  }))

  return (
    <BaseForm<Partial<PublicationAuthor>>
      forms={forms}
      setForms={setForms}
      initialState={PublicationUtils.authorInitialState}
      showAddMoreButton={inDrafts}
      onDelete={(item: PublicationAuthor) => item.id ? deleteAuthor(item) : null}
      addMoreButtonProps={{ onPress: handleInitialOrder, id: 'addMore-authors' }}
      formComponent={(props) => {
        return (
          // @ts-ignore
          <AuthorBaseForm
            ref={(ref) => (formsRefs.current[props.index] = ref)}
            scientificTags={scientificTags}
            disabled={['editor', 'publisher'].includes(profile?.current_role) || !inDrafts}
            regions={regions}
            {...props}
          />
        )
      }}
    />
  )
}
