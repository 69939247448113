/* eslint-disable prefer-const */
import { createSettings } from '@codeleap/common'
import Obfuscate from './Obfuscate'
import * as Sentry from '@sentry/gatsby'

export const IsDevEnv = process.env.NODE_ENV === 'development'
const isLocalBuild = process.env.SITE_URL === 'http://localhost:9000/'
const isStaging = process.env.SITE_URL === 'https://eworkflow-staging.codeleap.co.uk/'

const enableSentry = !IsDevEnv && !isStaging

const ENV = IsDevEnv ? 'development' : 'production'

const GATSBY_DEV_ENV = process.env.GATSBY_DEVELOPMENT_ENVIRONMENT === 'true'

const url = (IsDevEnv || isLocalBuild || isStaging) ? 'https://api-eworkflow.codeleap.co.uk/' : (
  GATSBY_DEV_ENV ? 'https://api-eworkflow.codeleap.co.uk/' : 'https://prod.eworkflow.com/'
)
const siteUrl = IsDevEnv ? 'https://eworkflow.codeleap.co.uk/' : (isStaging ? 'https://eworkflow-staging.codeleap.co.uk/' : (
  GATSBY_DEV_ENV ? 'https://eworkflow.codeleap.co.uk/' : 'https://app.eworkflow.com/'
))

const LINKEDIN_REDIRECT = IsDevEnv ? 'http://localhost:8000/auth/linkedIn/' : (
  GATSBY_DEV_ENV ? 'https://eworkflow.codeleap.co.uk/auth/linkedIn/' : 'https://app.eworkflow.com/auth/linkedIn/'
)

const FilePickerConfigs = {
  CreatePublication: {
    acceptFiles: { 'application/*': ['.docx', '.pdf', '.doc'] },
    acceptedExtensions: ['docx', 'pdf', 'doc'],
    pickerOptions: {
      width: 358,
      height: 264,
    },
  },
  Attachments: {
    max: 268435456, //256MB
  },
}

const CropPickerConfigs = {
  default: {
    width: 180,
    height: 240,
    aspect: 180 / 240,
  },
  cover: {
    width: 880,
    height: 200,
    aspect: 880 / 200,
  },
}

const CoverLetterConfigs = {
  min: 15,
  max: 10000,
}

if (enableSentry) {
  Sentry.init({
    dsn: 'https://eae1133565fb9d8bf768cdc62dbc2fff@o309198.ingest.sentry.io/4505981999775744',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maxReplayDuration: 60,
      }),
    ],
    tracesSampleRate: 0.3,
    // replaysSessionSampleRate: 0.1,
    // replaysOnErrorSampleRate: 1.0,
  })
}

let _Settings = createSettings({
  AppName: 'E-Workflow',
  CompanyName: 'Example Ltd.',
  Description: 'This is a template',
  SiteUrl: siteUrl,
  passcode: 'hFzu9aDCpmi3BM6D4bjd',
  AIReviewer: {
    email: 'ai+reviewer@eworkflow.com',
    id: '0cb817dc-525a-4d2b-bb31-59bb8366a345',
    name: 'eTelligence',
  },
  Environment: {
    IsDev: IsDevEnv,
    Type: ENV,
    InitTime: new Date(),
  },
  Application: {
    IsBrowser: false,
  },
  PerformanceInspector: {
    enable: true,
    maxRenders: 30,
  },
  Fetch: {
    ProductionURL: url,
    DevelopmentURL: url,
    ViewHTMLErrors: true,
  },
  Social: {
    FaceURL: 'https://www.facebook.com/codeleapuk/',
    LinkedinURL: 'https://www.linkedin.com/company/96414715/',
  },
  ContactINFO: {
    Website: 'app.eworkflow.com/',
    TermsAndPrivacy: 'https://eworkflow.com/terms-conditions/',
    CookiePolicy: 'https://eworkflow.com/cookie-policy/',
    PrivacyPolicy: 'https://eworkflow.com/privacy-policy/',
    SupportEMAIL: 'support@eworkflow.com',
    ContactEMAIL: 'support@eworkflow.com',
    ContactPHONE: '+44 (0) 333 050 9420',
  },
  Logger: {
    Level: 'debug',
    // TODO - get device or browser id
    DeviceIdentifier: '',
    StringifyObjects: true,
    IgnoreWarnings: [
      `[react-native-gesture-handler] Seems like you're using`,
      `Require cycle:`,
      `Require cycles are allowed`,
      `Running `,
      `WARN  Require cycle`,
      ` Warning: Failed`,
      `Warning: Failed`,
      'new NativeEventEmitter',
      'User cancelled',
      'React does not recognize the',
      'Unknown event handler property',
      'forwardRef render functions accept exactly ',

    ],
    Obfuscate,
    ComponentLogging: true,
  },
  Slack: {
    echo: {
      icon: 'https://avatars.githubusercontent.com/u/48894125?s=200&v=4',
      token: 'xoxb-622265672359-1248324007429-Ts31vPT8jCNh7L99xtdbOgQB',
      channel: '#_dev_logs',
    },
  },
  ApiCredentials: {
    GoogleSignin: {
      WebClientId:
        '384702319918-kvg1e4pcbgifmvm7fs9f9i8qnjg56qc6.apps.googleusercontent.com',
    },
    AppleSignIn: {
      ServiceClientId: 'uk.co.codeleap.eworkflow.web',
      RedirectURI: 'https://x-eworkflow.firebaseapp.com/__/auth/handler',
    },
    FacebookSDK: {
      AppId: '1130448934268035',
    },
    LinkedIn: {
      ClientID: '775j37lj8pt1os',
      ClientSecret: 'fhBVB2i9zmt3Ubnx',
      RedirectUri: LINKEDIN_REDIRECT,
    },
    GoogleMaps: {
      ApiKey: 'AIzaSyA7oLBstPckFkS3fkPI3bwV5Tf958RRjso',
    },
  },
  FilePickerConfigs,
  CropPickerConfigs,
  CoverLetterConfigs,
  OfficeUrl: 'https://view.officeapps.live.com/op/embed.aspx?src=',
  CookiePolicyURL: 'https://eworkflow.com/cookie-policy/',
  PrivacyPolicyURL: 'https://eworkflow.com/privacy-policy/',
  TermsAndConditionURL: 'https://eworkflow.com/terms-conditions/',
})

if (_Settings.Environment.IsDev) {
  // TODO read environment
  // _Settings = deepMerge(_Settings, env)
}

if (!_Settings.ContactINFO.TermsAndPrivacy) {
  _Settings.ContactINFO.TermsAndPrivacy = `https://codeleap.co.uk/dev/policies/?app=${Buffer.from(_Settings.AppName, 'base64')}`
}

export const Settings = _Settings
