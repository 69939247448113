import { FileCategory, Publication, PublicationStatus, PublicationStatusBooleans } from '@/types'
import { formatFileName } from './misc'
import { Attachment } from '@/components'
import { capitalize, deepEqual, TypeGuards } from '@codeleap/common'
import { Settings } from '@/app'

type FindCategoryParams = {
  categories: FileCategory[]
  key: string
}

type _Attachment = Attachment & {
  category?: number
}

type FilterFilesByCategoryParams = {
  attachments: _Attachment[]
  categoryValue: number
}

type Anchors = { [key: string]: string }

export const authorInitialState = {
  full_name: '',
  contribution: '',
  email: '',
  organisation: '',
  address: '',
  tags: [],
  region: '',
  order: null,
}

export const reviewerInitialState = {
  full_name: '',
  email: '',
  organisation: '',
  tags: [],
}

export const fieldAnchors = {
  cover_letter: 'cover-letter-anchor',
  publicationFields: 'article-anchor',
  detailFields: 'details-anchor',
  disclaimers: 'disclaimers-anchor',
  authors: 'authors-anchor',
  reviewers: 'reviewers-anchor',
}

const previewTitle = (publication: Publication) => publication?.title || publication?.short_title || formatFileName(publication?.file)

const applyFocusAndBlur = (element: HTMLElement) => {
  element.focus({ preventScroll: true })
  element.blur()
}

const handleFocusBlur = (anchors: Anchors = fieldAnchors) => {
  Object.values(anchors).forEach((anchorId) => {
    const section = document.getElementById(anchorId)
    if (section) {
      const inputs = section.querySelectorAll('input')

      inputs.forEach(input => {
        if (!input.id.includes('select')) {
          applyFocusAndBlur(input)
        }
      })
    }
  })
}

const findCategory = ({ categories, key }: FindCategoryParams) => categories?.find(category => !!category?.[key])

const filterFilesByCategory = ({ attachments, categoryValue }: FilterFilesByCategoryParams) => {
  return attachments?.filter(file => {
    if (file?.category !== undefined) {
      return file?.category === categoryValue
    } else {
      return file?.file_category === categoryValue
    }
  })
}

const validateAuthorOrder = (authorForms): boolean => {
  const authorOrders = authorForms?.map(({ order }) => Number(order))?.filter(order => !isNaN(order))

  const isValid = authorOrders?.every((authorOrder, index, arr) => {
    const isUnique = arr?.indexOf(authorOrder) === index
    const isPositive = authorOrder > 0
    const isInRange = authorOrder <= arr?.length

    return isUnique && isPositive && isInRange
  })

  return isValid
}

const sortSections = (data: Publication) => {
  let sections = []
  if (data?.sections && TypeGuards.isObject(data?.sections)) {
    const sortedObject = Object.entries(data.sections).sort(([, a], [, b]) => a.index - b.index)
    sections = sortedObject
  }

  return { breakdown: sections }
}

const getPublicationBooleans = (status: Publication['status']) => {
  const statusObject: PublicationStatusBooleans = {} as PublicationStatusBooleans
  Object.keys(PublicationStatus).forEach((k) => (statusObject[`is${capitalize(k)}`] = status === k))

  return statusObject
}

function verifyExistence<T extends { id: string | number }>(items: Partial<T>[], initialState: Partial<T>[]) {
  if (!items?.length) return
  return items.reduce<{ toUpdate: Partial<T>[]; new: Partial<T>[] }>(
    (acc, curr) => {
      if (curr?.id) {
        const originalAuthor = initialState.find((el) => el.id === curr.id)
        const hasChanges = !deepEqual(originalAuthor, curr)
        return { ...acc, toUpdate: hasChanges ? [...acc.toUpdate, curr] : [...acc.toUpdate] }
      }
      return { ...acc, new: [...acc.new, curr] }
    },
    { toUpdate: [], new: [] },
  )
}

const verifyPublicationChanges = (publication: Publication, ...args: any[]) => {
  if (!publication) return {}
  const changes = args.reduce((acc, cur) => ({ ...acc, ...cur }), {})

  return Object.entries(changes).reduce((acc, [key, val]) => {
    const value = publication?.[key]

    if (TypeGuards.isArray(val) && val.every((v) => value?.includes(v)) && val.length === value.length) return acc
    if (JSON.stringify(value) === JSON.stringify(val) || value === val) return acc
    if (TypeGuards.isNil(value) && TypeGuards.isNil(val)) return acc
    else return { ...acc, [key]: val }
  }, {})
}

export const PublicationUtils = {
  previewTitle,
  handleFocusBlur,
  findCategory,
  filterFilesByCategory,
  validateAuthorOrder,
  sortSections,
  getPublicationBooleans,
  verifyExistence,
  verifyPublicationChanges,
  authorInitialState,
  reviewerInitialState,
  fieldAnchors,
}
